import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { TIME_OPTIONS } from "../../constants/index.js"

export class PublishableState extends Component {

  render() {
    var time = null;
    var cz
    var ct
    if (this.props.value.publishState === 'UNPUBLISHED') {
      cz = 'not-executed'
      ct = 'Not published'
    } else if (this.props.value.publishState === 'PUBLISHING') {
      cz = 'executing'
      ct = 'Publishing';
      time = '(since ' + new Date(this.props.value.publishStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.publishState === 'UNPUBLISHING') {
      cz = 'executing'
      ct = 'Unpublishing';
      time = '(since ' + new Date(this.props.value.publishStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHED_PRIVATE') {
      cz = 'executed'
      ct = 'Published private'
      time = '(at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHED_PUBLIC') {
      cz = 'executed'
      ct = 'Published public'
      time = '(at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHED') {
      cz = 'executed'
      ct = 'Published'
      time = '(at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHING_FAILED') {
      cz = 'failed'
      ct = 'Publishing failed'
    } else {
      cz = 'not-executed'
      ct = 'Not published'
    }

    return (
      <div>
        <div className={cz}>
          {ct}
        </div>
        <div>
          {time &&
            <Row className="executeinfo">
              <Col md="auto">
                <span className='times'>{time}</span>
              </Col>
              <Col>
                <span className='times'>{this.props.value.publishDatabase !== null ? this.props.value.publishDatabase : ""}</span>
              </Col>
            </Row>}
          </div>
      </div>
    )
  }
}

export default PublishableState;
