import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactTooltip from 'react-tooltip';
import { Localizer } from '../../config/localizer.js'
import { filterByLanguage } from "../../utils/functions.js";
import { resolveJsonLdUri, objectToArray } from '../../utils/functions';

import AnnotatorModal from "./modals/AnnotatorModal.js";
import VocabulizerModal from "./modals/VocabulizerModal.js";
import ValidationCreateModal from "./modals/ValidationCreateModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import IndexableState from "./IndexableState.js";

import { getValidationProgress } from '../../utils/APIUtils.js'
import { isString } from "underscore";

export class SchemaProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      annotationModalOpen: false,
      vocabularizerModalOpen: false,
      annotatorsOpen: false,
      annotationsOpen: this.props.mode === 'validator',
      validationProgress: {},
      createValidationModalOpen: false,
      selectedAegId: null,
      selectedValidation: {},
      validationType: '',
      editMode: false,
      editAnnotatorId: null,
      chosenPartition: {}
    }

    this.prepareRender();
  }

  prepareRender() {
    this.setState({ chosenPartition: this.props.schema['@graph'].find(item => item['@id'] === this.props.value) })
  }

  prepareData() {
    this.prepareRender();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.prepareData();
  }

  componentDidMount() {
    this.prepareData();
  }

  toggleAnnotatorShow() {
    this.setState({ annotatorsOpen: !this.state.annotatorsOpen });
  }

  toggleAnnotationsShow() {
    this.setState({ annotationsOpen: !this.state.annotationsOpen });
  }

  adjustProperty(params) {
    if (params.onProperty) {
      return { ...params, onProperty: params.onProperty.concat(this.state.chosenPartition.hasOwnProperty(this.props.keys.classKey) ? this.state.chosenPartition[this.props.keys.classKey] : this.state.chosenPartition[this.props.keys.propertyKey]) }
    } else {
      return params;
    }
  }

  endValidation(el) {
    let b = document.getElementById(`stop-validation-${el.id}`);

    if (b.disabled) {
      return;
    }
    b.setAttribute("disabled", true);
    b.classList.add("disabled");

    let s = document.getElementById(`validation-status-${el.id}`);
    s.innerHTML = "Validation finished";

    this.props.actions('end-annotation-validation', { pavid: el.id });
  }

  openValidations(el, annotators) {
    var obj = {
      id: el.id,
      onProperty: this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey],
      currentPage: 0,
      requestedPage: null,
      totalPages: el.annotatedPagesCount,
      mode: "ANNOTATED_ONLY_SERIAL",
      annotators: annotators,
      serial: true,
      navigation: "RIGHT"
    };

    if (el.annotatedPagesCount === 0) {
      obj.mode = "UNANNOTATED_ONLY_SERIAL";
      obj.totalPages = el.nonAnnotatedPagesCount;
    }

    this.props.actions('validate-annotations', obj);
  }

  openValidationCreate(aegid, validation, type) {
    this.setState({ createValidationModalOpen: true, selectedAegId: aegid, selectedValidation: validation, validationType: type });
  }

  render() {
    // return (<div></div>);
    const annotators = this.props.annotators.filter(el => el.onProperty[0] === (this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]));
    // const annotationEditGroups = this.props.annotationEditGroups.filter(el => el.onProperty[0] === this.props.value['@id']);

    // const annotationEditGroups = [];
    const annotationEditGroups = this.props.annotationEditGroups.filter(el => el.onProperty[0] === (this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]));
    const asProperties = new Set();
    for (const i in annotators) {
      if (!asProperties.has(annotators[i].asProperty)) {
        asProperties.add(annotators[i].asProperty)
        // annotationEditGroups.push(annotators[i].editGroup);
      }
    }

    var vocabularizer = this.props.vocabularizers.filter(el => el.onProperty[0] === (this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]));
    if (vocabularizer.length > 0) {
      vocabularizer = vocabularizer[0]
    } else {
      vocabularizer = null
    }

    // var index = this.props.indexes.filter(el => el.onProperty[0] === this.props.value['@id']);
    // if (index.length > 0) {
    //   index = index[0]
    // } else {
    //   index = null
    // }

    // console.log(editGroups);
    // console.log(this.props.queryProperties);

    var fullView = this.props.mode === 'editor';

    let context = this.props.schema['@context'];

    var propertyPartition;
    var classPartition;
    let hasPropertyPartition = this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyPartitionKey);
    let hasClassPartition = this.state.chosenPartition.hasOwnProperty(this.props.keys.classPartitionKey);

    if (fullView || annotators.length > 0) {
      return (
        <Container>
        <Container className={"mt-0 mb-3 py-0 grouping groupborder" + (vocabularizer || annotators.length > 0 || (hasPropertyPartition && propertyPartition.length > 0) || (hasClassPartition && classPartition.length > 0) ? "" : "short")}>
          {<Row className={"mb-0 header schema-prop-bg-col " + (vocabularizer || annotators.length > 0 || (hasPropertyPartition && propertyPartition.length > 0) || (hasClassPartition && classPartition.length > 0) ? "" : "short")}>
            <Col>
              <Container>
                <Row>
                  <span>{this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? resolveJsonLdUri(this.state.chosenPartition[this.props.keys.propertyKey], context) : ''}</span>
                </Row>
                {this.state.chosenPartition.hasOwnProperty(this.props.keys.rangeIncludesKey) &&
                  <Row className="rangeIncludes">
                    {objectToArray(this.state.chosenPartition[this.props.keys.rangeIncludesKey]).map((el, index) =>
                     <span>{resolveJsonLdUri(el, context)}</span>)}
                  </Row>
                }
                {/* {this.state.chosenPartition.hasOwnProperty('property') ? this.state.chosenPartition.property : this.state.chosenPartition.class &&
                  <Row>
                    <span className="thin label">{filterByLanguage(this.props.value.info,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                    <span className="thin label">{this.state.chosenPartition.hasOwnProperty('property') ? this.state.chosenPartition.property : this.state.chosenPartition.class}</span>

                  </Row>} */}
              </Container>
            </Col>
            <Col className="thin" md="auto">
              <span>{this.state.chosenPartition[this.props.keys.triplesKey]}</span>
            </Col>
            {fullView &&
              <Col className="mybutton" md="auto">
                <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                  <Dropdown.Item className="py-2" onClick={() => this.props.valueActions('show-values', [{ type: "property", uri: this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey] }], "ALL")}>
                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>View values
                  </Dropdown.Item>
                  <Dropdown.Item className="py-2" onClick={() => this.props.valueActions('download-values', [{ type: "property", uri: this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey] }], "ALL")}>
                    <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download values
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="py-2" onClick={() => this.setState({ annotationModalOpen: true })}>
                    <span className="menu-icon fa fa-plus-square fa-lg mr-3"></span>Add Annotator
                  </Dropdown.Item>
                  {!vocabularizer &&
                    <Dropdown.Item className="py-2" onClick={() => this.setState({ vocabularizerModalOpen: true })}>
                      <span className="menu-icon fa fa-th-list fa-lg mr-3"></span>Vocabularize
                    </Dropdown.Item>
                  }
                  {/*{!index &&
                    <Dropdown.Item  onClick={() => this.props.actions('index', { onProperty: [this.props.value['@id']] })}>Index</Dropdown.Item>
                  }
                  <Dropdown.Item  onClick={() => this.props.actions('unindex', { onProperty: [this.props.value['@id']] })}>Unindex</Dropdown.Item>
                  <Dropdown.Item  onClick={() => this.props.actions('unpublish-annotators', { uri: this.props.value['@id']})}>Unpublish Annotators</Dropdown.Item>
                  */}
                  {annotators.length > 0 &&
                    <React.Fragment>
                      <Dropdown.Divider />
                      <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('publish-checked-annotators', { onProperty: [this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]] })}>
                        <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"></span>Publish Selected Annotators
                      </Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('unpublish-checked-annotators', { onProperty: [this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]] })}>
                        <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish Selected Annotators
                      </Dropdown.Item>
                    </React.Fragment>
                  }
                </DropdownButton>

                {this.state.annotationModalOpen && <AnnotatorModal show={this.state.annotationModalOpen}
                  edit={this.state.editMode}
                  editAnnotatorId={this.state.editAnnotatorId}
                  queryProperties={this.props.queryProperties}
                  dataAnnotators={this.props.dataAnnotators}
                  preprocessFunctions={this.props.preprocessFunctions}
                  vocabularies={this.props.vocabularies}
                  onOK={(property, annotator, thesaurus, params, preprocess, variant) => { this.props.actions('create-annotator', { onProperty: [this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]], asProperty: property, annotator: annotator, thesaurus: thesaurus, parameters: params, preprocess: preprocess, variant: variant }); this.setState({ annotationModalOpen: false }) }}
                  onUpdate={(id, property, annotator, thesaurus, params, preprocess, variant) => { this.props.actions('edit-annotator', { id: id, asProperty: property, annotator: annotator, thesaurus: thesaurus, parameters: params, preprocess: preprocess, variant: variant }); this.setState({ annotationModalOpen: false, editMode: false, editAnnotatorId: null }) }}
                  onClose={() => this.setState({ annotationModalOpen: false, editMode: false, editAnnotatorId: null })} />}

                {this.state.vocabularizerModalOpen && <VocabulizerModal show={this.state.vocabularizerModalOpen}
                  onOK={(name, separator) => { this.props.actions('create-vocabularizer', { onProperty: [this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey]], name: name, separator: separator }); this.setState({ vocabularizerModalOpen: false }) }}
                  onClose={() => this.setState({ vocabularizerModalOpen: false })} />}

              </Col>}
          </Row>}

          {/*        <Row>
              <Col>
                {Localizer.count[this.props.language]}: <span>{this.state.chosenPartition[this.state.triplesKey]}</span>
              </Col>
            </Row> */}
          {/*        {index &&
            <div>
              <Row className="tableheader">
              </Row>
              <Row className="tableheader">
                <Col>
                  <span>Index</span>
                </Col>
                <Col md="4" className="tablecenter">
                    <Row>
                      <Col>
                        <IndexableState value={index}/>
                      </Col>
                    </Row>
                </Col>
                <Col className="mybutton" md="auto">
                    <Button type="button" className="menubutton" aria-label="Unindex" onClick={() => this.props.actions('unindex', { onProperty: [this.props.value['@id']] })}><span className="fa fa-trash"></span></Button>
                </Col>
              </Row>
            </div>} */}
          {fullView && vocabularizer &&
            <div>
              <Row className="tableheader">
              </Row>
              <Row className="tableheader">
                <Col>
                  <Row>
                    <Col>
                      <span>Vocabularizer</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span><b>{vocabularizer.name}</b></span>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" className="tablecenter">
                  <div>
                    <Row>
                      <Col>
                        <ExecutableState value={vocabularizer} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PublishableState value={vocabularizer} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <IndexableState value={vocabularizer} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-vocabularizer', { id: vocabularizer.id })}><span className="fa fa-play-circle"></span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="View Last Execution" onClick={() => this.props.actions('view-vocabularizer-execution', { id: vocabularizer.id })}><span className="fa fa-eye"></span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Delete" onClick={() => this.props.actions('delete-vocabularizer', { id: vocabularizer.id })}><span className="fa fa-trash"></span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Publish" onClick={() => this.props.actions('publish-vocabularizer', { id: vocabularizer.id })}><span className="punp">P</span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Unpublish" onClick={() => this.props.actions('unpublish-vocabularizer', { id: vocabularizer.id })}><span className="punp">U</span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Index" onClick={() => this.props.actions('index-vocabularizer', { id: vocabularizer.id })}><span className="punp">X</span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Unindex" onClick={() => this.props.actions('unindex-vocabularizer', { id: vocabularizer.id })}><span className="punp">U</span></Button>
                </Col>

              </Row>
            </div>}
          {fullView && vocabularizer &&
            <Row>
              <Col>
                Clean up
              </Col>
              <Col md="4" className="tablecenter">
                {/*              <div>
                    <Row>
                      <Col>
                        <ExecutableState value={el.editGroup}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PublishableState value={el.editGroup}/>
                      </Col>
                    </Row>
                  </div>*/}
              </Col>
              <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-cleanup-vocabularizer', { id: vocabularizer.id })}><span className="fa fa-edit"></span></Button>
              </Col>
              {/*            <Col className="mybutton" md="auto">
                    <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-validation', {id: el.editGroup.id})}><span className="fa fa-play-circle"></span></Button>
                </Col> */}
              <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="View" onClick={() => this.props.actions('view-cleanup-vocabularizer', {})}><span className="fa fa-eye"></span></Button>
              </Col>
              {/*            <Col className="mybutton" md="auto">
                    <Button type="button" className="menubutton" aria-label="Publish" onClick={() => this.props.actions('publish-validation', {id: el.editGroup.id})}><span className="punp">P</span></Button>
                </Col>
                <Col className="mybutton" md="auto">
                    <Button type="button" className="menubutton" aria-label="Unpublish" onClick={() => this.props.actions('unpublish-validation', {id: el.editGroup.id})}><span className="punp">U</span></Button>
                </Col>*/}
            </Row>}
          {fullView && vocabularizer &&
            <Row className="tableheader">
            </Row>}
          {annotators.length > 0 &&
            <div>
              {/*fullView && <Row className="tableheader">
                </Row>*/}
              {fullView && <Row className="tableheader annotators align-items-center py-1">
                <Col>
                  <span>{Localizer.annotators[this.props.language]}</span>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton mt-0" aria-label="Toggle" onClick={() => this.toggleAnnotatorShow()}><span className={this.state.annotatorsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>
              </Row>}
              {fullView && <Collapse in={this.state.annotatorsOpen}>
                <div>
                  {annotators.map((el, index) =>
                    <Row key={index} className="grouping bottomrow mx-0">
                      <Col md="auto px-0">
                        <Form.Check value={el.selected} onClick={() => this.props.actions('check-annotator', { id: el.id })} />
                      </Col>
                      <Col className="text-break">
                        <Row>
                          <Col><span className="crimson-std">{this.props.dataAnnotators.filter((ann) => ann.identifier === el.annotator)[0].title}</span>{!el.variant ? " as" : ""}</Col>
                        </Row>
                        {el.variant &&
                          <Row>
                            <Col>[<span className="crimson-std">{el.variant}</span>] as</Col>
                          </Row>
                        }
                        <Row>
                          <Col><span className="blueviolet-std">{el.asProperty}</span></Col>
                        </Row>
                        {el.parameters && el.parameters.length > 0 &&
                          <div>
                            <Row className="tableheader">
                            </Row>
                            <Row className="tableheader">
                              <Col>{Localizer.parameters[this.props.language]}</Col>
                            </Row>
                          </div>
                        }
                        {el.thesaurus &&
                          <Row>
                            <Col><span className="parameter">thesaurus: </span><span className="parametervalue">{this.props.vocabularies.find(v => v['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === el.thesaurus) ? filterByLanguage(this.props.vocabularies.find(v => v['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === el.thesaurus), 'http://www.w3.org/2000/01/rdf-schema#label', this.props.language) : el.thesaurus}</span></Col>
                          </Row>
                        }
                        {el.parameters && el.parameters.map((el2, index2) =>
                          <Row key={index2}>
                            <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                          </Row>
                        )}
                        {el.preprocess && el.preprocess.length > 0 &&
                          <div>
                            <Row className="tableheader">
                            </Row>
                            <Row className="tableheader">
                              <Col>Preprocess</Col>
                            </Row>
                          </div>}
                        {el.preprocess && el.preprocess.map((el1, index1) =>
                          <Row key={"function-" + index1}>
                            <Col md="auto">{index1 + 1}</Col>
                            <Col>
                              <Row key={index1}>
                                <Col><span className="parameter">function: </span><span className="parametervalue">{el1.function}</span></Col>
                              </Row>
                              {el1.parameters.map((el2, index2) =>
                                <Row key={index2}>
                                  <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                                </Row>
                              )}
                            </Col>
                          </Row>)}
                      </Col>
                      <Col md="4" className="tablecenter mx-3">
                        <div>
                          <Row>
                            <Col>
                              <ExecutableState value={el} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <PublishableState value={el} />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className="mybutton pr-0" md="auto">
                        <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                          <ReactTooltip delayShow={200} place="right" />
                          <div data-tip={el.publishState === "PUBLISHED" ? "Only unpublished annotators can be edited" : null} data-effect="solid">
                            <Dropdown.Item className="py-2" disabled={el.publishState === "PUBLISHED"} onClick={() => this.setState({ editMode: true, annotationModalOpen: true, editAnnotatorId: el.id })}>
                              <span className="menu-icon fa fa-edit fa-lg mr-3"></span>Edit
                            </Dropdown.Item>
                          </div>
                          <Dropdown.Item className="py-2" disabled={el.publishState !== "UNPUBLISHED"} onClick={() => this.props.actions('delete-annotator', { id: el.id })}>
                            <span className="menu-icon fa fa-trash fa-lg mr-3"></span>Delete
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item className="py-2" onClick={() => this.props.actions('execute-annotator', { id: el.id })}>
                            <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                          </Dropdown.Item>
                          {el.executeState === 'EXECUTED' &&
                            <React.Fragment>
                              <Dropdown.Item className="py-2" disabled={el.executeState !== 'EXECUTED'} onClick={() => this.props.actions('view-annotator-execution', { id: el.id })}>
                                <span className="menu-icon fa fa-eye fa-lg mr-3"></span>View Execution (RDF)
                              </Dropdown.Item>
                              <Dropdown.Item className="py-2" disabled={el.executeState !== 'EXECUTED'} onClick={() => this.props.actions('preview-annotator-execution', { id: el.id, page: 1 })}>
                                <span className="menu-icon fa fa-list-alt fa-lg mr-3"></span>Preview Annotations
                              </Dropdown.Item>
                              <Dropdown.Item className="py-2" disabled={el.executeState !== 'EXECUTED'} onClick={() => this.props.actions('download-annotator-execution', { id: el.id })}>
                                <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download
                              </Dropdown.Item>
                            </React.Fragment>
                          }
                        </DropdownButton>
                      </Col>
                    </Row>)}
                </div>
              </Collapse>}
              {/*<Row className="tableheader"></Row>*/}
              {fullView &&
                <Row className="tableheader annotators align-items-center py-1">
                  <Col>
                    <span>Published Annotations</span>
                  </Col>
                  <Col className="mybutton">
                    <Button type="button" className="menubutton mt-0" aria-label="Toggle" onClick={() => this.toggleAnnotationsShow()}><span className={this.state.annotationsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                  </Col>
                </Row>
              }
              <Collapse in={this.state.annotationsOpen}>
                <div>
                  {annotationEditGroups.map((el, index) =>
                    <div key={"annotationEditGroup-" + index} className="bottomrow-bold">
                      <Row className="grouping bottomrow mx-0">
                        {fullView &&
                          <React.Fragment>
                            <Col className="align-self-center font-weight-bold text-break my-2 pl-0" md={10}>
                              {el.asProperty}
                            </Col>
                            <Col className="align-self-center pr-0" md={2}>
                              <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                                <Dropdown.Item className="py-2" onClick={() => this.props.actions('view-annotations', { id: el.id, page: 1, mode: "ALL", annotators: annotators })}>
                                  <span className="menu-icon fa fa-edit fa-lg mr-3"></span>View Annotations
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="py-2" id={'prepare-paged-validation-' + el.id} onClick={() => this.openValidationCreate(el.id, null, 'paged')}>
                                  <span className="menu-icon fa fa-file-text-o fa-lg mr-3"></span>New Paged Validation
                                </Dropdown.Item>
                                <Dropdown.Item className="py-2" id={'prepare-filtered-validation-' + el.id} onClick={() => this.openValidationCreate(el.id, null, 'filter')}>
                                  <span className="menu-icon fa fa-filter fa-lg mr-3"></span>New Filter Validation
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="py-2" onClick={() => this.props.actions('download-annotation-values', { id: el.id, mode: 'ALL' })}>
                                  <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download Values Before Validation
                                </Dropdown.Item>
                                <Dropdown.Item className="py-2" onClick={() => this.props.actions('download-annotation-values', { id: el.id, mode: 'NON_DELETED' })}>
                                  <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download Values After Validation
                                </Dropdown.Item>
                              </DropdownButton>
                            </Col>
                          </React.Fragment>
                        }
                        {!fullView && el.pagedAnnotationValidations && el.pagedAnnotationValidations.length > 0 &&
                          <Col className="pl-0 text-center">
                            <Row className="px-3">
                              <Col className="text-left px-0">
                                {el.asProperty}
                              </Col>
                              <Col className="text-right px-0">
                                Validated: <strong>{this.props.validationProgress[el.pagedAnnotationValidations[0].id]}%</strong>
                              </Col>
                            </Row>
                            <Row className="px-3">
                              <ProgressBar
                                animated
                                className="mt-2 mb-1 w-100"
                                now={this.props.validationProgress[el.pagedAnnotationValidations[0].id]}
                              />
                            </Row>
                          </Col>
                        }
                        {(!fullView && el.pagedAnnotationValidations.length > 0) &&
                          <Col className="mybutton align-self-center" md="auto">
                            <Button type="button" className="menubutton" aria-label="Validate" title="Validate" disabled={el.pagedAnnotationValidations[0].complete} onClick={() => this.openValidations(el.pagedAnnotationValidations[0], annotators)}>
                              <span className="fa fa-list-alt"></span>
                            </Button>
                          </Col>
                        }
                      </Row>
                      {fullView && el.pagedAnnotationValidations.length > 0 &&
                        <div className="bottomrow mb-2">
                          <Row className="align-self-center font-weight-bold mx-0 mb-2">
                            Paged Validations:
                          </Row>
                          {el.pagedAnnotationValidations.map((pav, index) =>
                            <Row key={"pav-" + pav.id} className="grouping mx-0">
                              <Col className="align-self-center">
                                {(pav.name && pav.name !== '') ? pav.name : pav.uuid}
                              </Col>
                              <Col md="auto" className="tablecenter align-self-center w-40">
                                <div>
                                  <Row>
                                    <Col>
                                      <ExecutableState value={pav} />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <PublishableState value={pav} />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id={'validation-status-' + pav.id} className="executed">
                                      {pav.complete ? "Validation finished" : "Validation started"}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col className="align-self-center w-10 pr-0">
                                <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                                  <Dropdown.Item className="py-2" onClick={() => this.openValidationCreate(el.id, pav, 'paged')}>
                                    <span className="menu-icon fa fa-pencil fa-lg mr-3"></span>Edit
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item className="py-2" onClick={() => this.openValidations(pav, annotators)}>
                                    <span className="menu-icon fa fa-list-alt fa-lg mr-3"></span>Open validations
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" id={'stop-validation-' + pav.id} disabled={pav.complete} onClick={() => this.endValidation(pav)}>
                                    <span className="menu-icon fa fa-lock fa-lg mr-3"></span>Stop validation
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('execute-paged-validation', { id: pav.id })}>
                                    <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('view-paged-validation', { id: pav.id })}>
                                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>View
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('publish-paged-validation', { id: pav.id })}>
                                    <span className="menu-icon fa fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" disabled={!pav.publishState} onClick={() => this.props.actions('unpublish-paged-validation', { id: pav.id })}>
                                    <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                  </Dropdown.Item>
                                </DropdownButton>
                              </Col>
                            </Row>
                          )}
                        </div>
                      }
                      {fullView && el.filterAnnotationValidations.length > 0 &&
                        <React.Fragment>
                          <Row className="align-self-center font-weight-bold mx-0 mb-2">
                            Filter Validations:
                          </Row>
                          {el.filterAnnotationValidations.map((fv, index) =>
                            <Row key={"fv-" + fv.id} className="grouping mx-0">
                              <Col className="align-self-center">
                                {(fv.name && fv.name !== '') ? fv.name : fv.uuid}
                              </Col>
                              <Col md="auto" className="tablecenter align-self-center w-40">
                                <div>
                                  <Row>
                                    <Col>
                                      <ExecutableState value={fv} />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <PublishableState value={fv} />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col className="align-self-center w-10 pr-0">
                                <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                                  <Dropdown.Item className="py-2" onClick={() => this.openValidationCreate(el.id, fv, 'filter')}>
                                    <span className="menu-icon fa fa-pencil fa-lg mr-3"></span>Edit
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('execute-filter-validation', { id: fv.id })}>
                                    <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('view-filter-validation', { id: fv.id })}>
                                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>View
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('publish-filter-validation', { id: fv.id })}>
                                    <span className="menu-icon fa fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                  </Dropdown.Item>
                                  <Dropdown.Item className="py-2" disabled={!fv.publishState} onClick={() => this.props.actions('unpublish-filter-validation', { id: fv.id })}>
                                    <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                  </Dropdown.Item>
                                </DropdownButton>
                              </Col>
                            </Row>
                          )}
                        </React.Fragment>
                      }
                    </div>)
                  }
                </div>
              </Collapse>
            </div>}
          {hasPropertyPartition &&
            <Row>
              {objectToArray(this.state.chosenPartition[this.props.keys.propertyPartitionKey]).map((el, index) =>
                <SchemaProperty key={index} value={el}
                  schema={this.props.schema}
                  keys={this.props.keys}
                  mode={this.props.mode}
                  annotators={this.props.annotators}
                  annotationEditGroups={this.props.annotationEditGroups}
                  vocabularizers={this.props.vocabularizers}
                  indexes={this.state.indexes}
                  queryProperties={this.props.queryProperties}
                  dataAnnotators={this.props.dataAnnotators}
                  vocabularies={this.props.vocabularies}
                  preprocessFunctions={this.props.preprocessFunctions}
                  language={this.props.language}
                  actions={(action, params) => this.props.actions(action, this.adjustProperty(params))}
                  valueActions={(action, path, mode) => this.props.valueActions(action, [{ type: "property", uri: this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey] }].concat(path), mode)} />
              )}
            </Row>}
          {hasClassPartition &&
            <Row>
              {objectToArray(this.state.chosenPartition[this.props.keys.classPartitionKey]).map((el, index) =>
                <SchemaProperty key={index} value={el}
                  keys={this.props.keys}
                  mode={this.props.mode}
                  annotators={this.props.annotators}
                  annotationEditGroups={this.props.annotationEditGroups}
                  vocabularizers={this.props.vocabularizers}
                  indexes={this.state.indexes}
                  queryProperties={this.props.queryProperties}
                  dataAnnotators={this.props.dataAnnotators}
                  vocabularies={this.props.vocabularies}
                  preprocessFunctions={this.props.preprocessFunctions}
                  language={this.props.language}
                  actions={(action, params) => this.props.actions(action, this.adjustProperty(params))}
                  valueActions={(action, path, mode) => this.props.valueActions(action, [{ type: "property", uri: this.state.chosenPartition.hasOwnProperty(this.props.keys.propertyKey) ? this.state.chosenPartition[this.props.keys.propertyKey] : this.state.chosenPartition[this.props.keys.classKey] }].concat(path), mode)} />
              )}
            </Row>}
          {this.state.createValidationModalOpen &&
            <ValidationCreateModal
              show={this.state.createValidationModalOpen}
              aegId={this.state.selectedAegId}
              validation={this.state.selectedValidation}
              type={this.state.validationType}
              onOK={(action, params) => { this.props.actions(action, params); this.setState({ createValidationModalOpen: false }) }}
              onClose={() => this.setState({ createValidationModalOpen: false })}
            />
          }
        </Container>
        </Container>
      );
    } else {
      return (<div />);
    }
  }
}


export default SchemaProperty;
