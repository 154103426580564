import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getDatasetSchema, getDatasetPropertyValues, getAnnotators, getAnnotatorsByUser, publishAnnotators, unpublishAnnotators, downloadDatasetPropertyValues, updateAnnotator, previewAnnotator, getDatasetSchemaNew } from '../../utils/APIUtils';
import { resolveJsonLdUri, objectToArray } from '../../utils/functions';
import PropertyValuesModal from "./modals/PropertyValuesModal.js";
import SchemaProperty from "./SchemaProperty.js";
import ResultsModal from "./modals/ResultsModal.js";
import AnnotationValuesModal from "./modals/AnnotationValuesModal.js";
import ValidationModal from "./modals/ValidationModal.js";
import VocabularyValuesModal from "./modals/VocabularyValuesModal.js";
//import IndexableState from "./IndexableState.js";
import DeleteModal from "./modals/DeleteModal.js";
import { isEqual } from 'lodash';

import { createAnnotator,  executeAnnotator, viewAnnotationExecution, downloadAnnotationExecution, deleteAnnotator, viewValuesAnnotations } from '../../utils/APIUtils.js'
import { commitAnnotationEdits,  viewAnnotationEditsExecution, downloadAnnotationValues, getValidationProgress } from '../../utils/APIUtils.js'
import { endAnnotationValidation, viewAnnotationValidation, commitAnnotationValidationPage, getAnnotationEditGroups, getAnnotationEditGroupsByUser } from '../../utils/APIUtils.js'
import { createPagedAnnotationValidation, executePagedAnnotationValidation, viewPagedAnnotationValidationExecution, publishPagedAnnotationValidation, unpublishPagedAnnotationValidation } from '../../utils/APIUtils.js'
import { createFilterAnnotationValidation, updateFilterAnnotationValidation, executeFilterAnnotationValidation, viewFilterAnnotationValidationExecution, publishFilterAnnotationValidation, unpublishFilterAnnotationValidation  } from '../../utils/APIUtils.js'
import { createVocabularizer, deleteVocabularizer, executeVocabularizer, cleanupVocabularizer, vocabularyVocabularizer, publishVocabularizer, unpublishVocabularizer, viewVocabularizerExecution, getVocabulizers, indexVocabularizer, unindexVocabularizer } from '../../utils/APIUtils.js'
import { index, unindex, getIndexes } from '../../utils/APIUtils.js'

import { Localizer } from '../../config/localizer.js'
import { filterByLanguage } from "../../utils/functions.js";
import BarLoader from "react-spinners/BarLoader";

export class PublishedSchema extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: [],
      topParents: [],
      schemaState: { loaded: false, loading:false, failed:false },

      annotators: [],
      annotationEditGroups: [],
      vocabularizers: [],
      valitations: [],
      indexes: {},

      valuesModalShow: false,
      values: [],
      valuesState: { loaded: false, loading:false, failed:false },

      annotationsModalShow: false,
      annotations: null,
      annotationsState: { loaded: false, loading:false, failed:false },

      annotationsEditModalShow: false,
      validationModalShow: false,
      annotationsEdit: [],
      annotationsEditState: { loaded: false, loading:false, failed:false },
      annotationsEditOnProperty: null,
      // annotationsEditGroup: null,
      // annotationsEditAsProperty: null,
      editGroupId: null,
      annotationsEditMode: "ALL",

      vocabularyEditModalShow: false,
      vocabulary: new Map(),

      annotationEditExecutionModalShow: false,
      annotationEditExecutions: [],
      annotationEditExecutionsState: { loaded: false, loading:false, failed:false },

      annotationValidations: {},
      annotationValidationsModalShow: false,
      annotationValidationsState: { loaded: false, loading:false, failed:false },

      validationProgress: {}
    };
  }

  handleServerEvent(type, message) {
    // console.log(type);
    // console.log(message);
    if(type==='filter-annotation-validation' || type==='paged-annotation-validation'){
      if(message.type==='publish' || message.type === 'execute'){
        getAnnotationEditGroupsByUser(this.props.schemaDataset['@id'])
        .then(json => {this.setState({ annotationEditGroups: json })})
      }
    }

    if (type === 'annotator') {
      if (message.type === 'publish') {
        this.setState({ annotators: this.state.annotators.map(el =>
              el.id !== message.id ?
                 el :
                { ...el, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt}
          )});
      }

      if (message.state === "EXECUTING") {
        if (message.startedAt) {
          // console.log(message.startedAt);
            this.setState({ annotators: this.state.annotators.map(el =>
                el.id !== message.id ?
                   el : {...el,  count:message.count, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, maps: message.maps.map(m => {return{...m, completed:false}})}
            )})
        } else if (message.executionInfo) {
          if (message.executionInfo) {
              this.setState({ annotators: this.state.annotators.map(el =>
                  el.id !== message.id ?
                     el : {...el, [message.type + 'State']: message.state,  [message.type + 'CompletedAt']: message.completedAt,
                     maps: el.maps ?
                       (el.maps.some(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource) ?
                          el.maps.map(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource ?
                             message.executionInfo : map) : [...el.maps, message.executionInfo])
                       : [message.executionInfo]}
              )})
          }
        }
      } else if (message.state === "EXECUTED") {
          this.setState({ annotators: this.state.annotators.map(el =>
              el.id !== message.id ?
                 el : {...el, count:message.count, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt}
          )})
      }

    } else if (type === 'filter-validation') {
      this.setState({ annotationEditGroups: this.state.annotationEditGroups.map(el =>
          el.id !== message.id ?
             el :
            { ...el, editGroup: { ...el.editGroup, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt}}
      )});
    } else if (type === 'index') {
      if (this.props.schemaDataset['@id'] === "http://sw.islab.ntua.gr/semaspace/resource/dataset/" + message.id) {
        this.setState({ indexes: { [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt} })
      }
    }
  }


componentWillReceiveProps(props) {
  if (props.schemaDataset === null) {
    this.setState({ schema: [], annotators: [] })
  }
  else if (!this.props.schemaDataset || (this.props.schemaDataset['@id'] !== props.schemaDataset['@id'])) {
    this.setState({ schemaState: { loaded: false, loading: true, failed: false}},
      () =>
      getDatasetSchemaNew(props.schemaDataset['@id']).then(response => {
        if(!response.hasOwnProperty('@graph')){
          this.setState({ topParents: [], schemaState: {loaded: true, loading: false, failed: false}})
          return;
        }

        var keys = {} ;
        let graph = response['@graph'];
        let context = response['@context'];

        let top = null;
       for(let i = graph.length - 1; i >= 0; i--) {
         var obj = graph[i];

         for (const [key, value] of Object.entries(obj)) {
           if (key === '@id') {
             let resolvedValue = resolveJsonLdUri(value, context)
             if (resolvedValue === props.schemaDataset['@id']) {
               top = obj;
             }
           } if (key.startsWith('@')) {
             continue;
           }

            let resolvedKey = resolveJsonLdUri(key, context);

            if (resolvedKey === 'http://rdfs.org/ns/void#propertyPartition') {
              keys.propertyPartitionKey = key
            } else if (resolvedKey === 'http://rdfs.org/ns/void#classPartition') {
              keys.classPartitionKey = key;
            } else if (resolvedKey === 'http://rdfs.org/ns/void#class') {
              keys.classKey = key;
            } else if (resolvedKey === 'http://rdfs.org/ns/void#property') {
              keys.propertyKey = key;
            } else if (resolvedKey === 'http://rdfs.org/ns/void#entities') {
              keys.entitiesKey = key;
            } else if (resolvedKey === 'http://rdfs.org/ns/void#triples') {
              keys.triplesKey = key;
            } else if (resolvedKey === 'http://purl.org/dc/dcam/rangeIncludes') {
              keys.rangeIncludesKey = key;
            }
         }
       }

      var topParents = top.hasOwnProperty(keys.classPartitionKey) ? top[keys.classPartitionKey] : top[keys.propertyPartitionKey];

      let topParentsObjects = [];
      topParents.forEach(id => topParentsObjects.push(graph.find(item => item['@id'] === id)))

      this.setState({ topParents: topParentsObjects, keys: keys, schema:response, schemaState: {loaded: true, loading: false, failed: false}});

      },
      () => this.setState({ schema: [], schemaState: {loaded: false, loading: false, failed: true}}))
    );
    if (props.mode === 'editor') {
      getAnnotatorsByUser(props.schemaDataset['@id'])
        .then(json => {this.setState({ annotators: json })})

      getAnnotationEditGroupsByUser(props.schemaDataset['@id'])
        .then(json => {this.setState({ annotationEditGroups: json })})

      getVocabulizers(props.schemaDataset['@id'])
        .then(json => {this.setState({ vocabularizers: json })})

      getIndexes(props.schemaDataset['@id'])
        .then(json => {this.setState({ indexes: json })})
    }
    else {
      getAnnotators(props.schemaDataset['@id'])
        .then(json => {this.setState({ annotators: json })})

      getAnnotationEditGroups(props.schemaDataset['@id'])
        .then(json => {
          let pavIds = [];
          for (let el of json) {
            for (let pav of el.pagedAnnotationValidations) {
              pavIds.push(pav.id);
            }
          }
          for (let id of pavIds) {
            getValidationProgress(id)
              .then(response => {
                let progs = this.state.validationProgress;
                progs[id] = response.totalAnnotations > 0 ? (100 * response.totalValidations / response.totalAnnotations).toFixed(2) : 0;
                this.setState({ annotationEditGroups: json, validationProgress: progs });
              })
          }
        })

    }
  }
}


  valueActions(action, uri, path, mode) {
    if (action === 'show-values') {
      this.setState({ values: [], valuesModalShow: true, valuesPath: [], valuesState: { loaded: false, loading:true, failed:false }}, () =>
        getDatasetPropertyValues(uri, path,  mode)
          .then(json => this.setState({ values: json, valuesPath: path, valuesState: { loaded: true, loading:false, failed:false } }),
                () => this.setState({ values: [], valuesPath: [], valuesState: { loaded: false, loading:false, failed:true } }),
        ))
      } else if (action === 'download-values') {
        downloadDatasetPropertyValues(uri, path,  mode)
            .then((response) => {
              if (response.ok) {
                const filename = response.headers.get('content-disposition')
                  .split(';')
                  .find(n => n.includes('filename='))
                  .replace('filename=', '')
                  .trim();

                  response.blob().then(blob => {
              					let url = window.URL.createObjectURL(blob);
              					let a = document.createElement('a');
              					a.href = url;
              					a.download = filename;
              					a.click();
              				});
                } else {
                   Promise.reject(response)
                }},
                () => Promise.reject())
      }
    }

  validationsSort(a,b) {
    if (a.name && b.name) {
         if (a.name < b.name) {
           return -1;
         } else if (a.name > b.name) {
           return 1;
         } else {
           return 0;
         }
    } else {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  schemaActions(action, params) {
      if (action === 'create-annotator') {
        // console.log(this.props.schemaDataset['@id'], params.onProperty, params.asProperty, params.annotator, params.thesaurus, params.parameters, params.preprocess, params.variant)
        createAnnotator(this.props.schemaDataset['@id'], params.onProperty, params.asProperty, params.annotator, params.thesaurus, params.parameters, params.preprocess, params.variant)
          .then((json) =>
            this.setState({ annotators: this.state.annotators.slice().concat(json) })
          )
      }
      else if (action === 'edit-annotator') {
        updateAnnotator(params.id, params.asProperty, params.annotator, params.thesaurus, params.parameters, params.preprocess, params.variant)
          .then(() => {
            getAnnotatorsByUser(this.props.schemaDataset['@id'])
              .then(json => {this.setState({ annotators: json })})
      }
          )
      } else if (action === 'execute-annotator') {
        executeAnnotator(params.id)
           .then(() =>
              this.setState({ annotators: this.state.annotators.map(el =>
                 el.id !== params.id ?
                  el :
                 { ...el, executeStartedAt: new Date(), executeCompletedAt: null }
           )})
         )
       } else if (action === 'delete-annotator') {
         this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-annotator', deleteModalParams:params})
       } else if (action === 'delete-annotator-ok') {
         deleteAnnotator(params.id)
            .then(() =>
               this.setState({ annotators: this.state.annotators.filter(el => el.id !== params.id)}))
      } else if (action === 'check-annotator') {
        this.setState({ annotators: this.state.annotators.map(el =>
            el.id !== params.id ?
               el :
              { ...el, selected: !el.selected}
          )});

      } else if (action === 'view-annotator-execution') {
      //   viewAnnotationExecution(params.id)
      //   .then(response => {
      //    if (response.status === 204) {
      //      // this.setState({ lastExecution: { message: "The mapping has not been executed." }})
      //      return;
      //    } else if (!response.ok) {
      //     return "Error";
      //   } else {
      //     response.text().then(text =>
      //       this.setState({ annotations: { text: text }, annotationsModalShow:true}))
      //   }
      // })
      this.setState({ annotations: null, annotationsModalShow:true, annotationsState: { loaded: false, loading: true, failed: false}}, () =>
        viewAnnotationExecution(params.id)
          .then(response => {
            response.text().then(text =>
              this.setState({ annotations: { text: text }, annotationsState: {loaded: true, loading: false, failed: false} }))},
              () => this.setState({ annotations: null, annotationsState: {loaded: false, loading: false, failed: true}}) )
        )

    } else if (action === 'download-annotator-execution') {
      downloadAnnotationExecution(params.id)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

              response.blob().then(blob => {
          					let url = window.URL.createObjectURL(blob);
          					let a = document.createElement('a');
          					a.href = url;
          					a.download = filename;
          					a.click();
          				});
            } else {
               Promise.reject(response)
            }},
            () => Promise.reject())
      } else if (action === 'preview-annotator-execution') {
      //   this.setState({ annotationsEdit: null, annotationsEditModalShow:true, annotationsEditState: { loaded: false, loading: true, failed: false}}, () =>
      //   viewValuesAnnotations(this.props.schemaDataset['@id'], params.annotators.map(an => an.uuid), params.onProperty, params.asProperty, params.page, params.mode)
      //   .then(json => {
      //       this.setState({ editGroupId: params.id, annotationsEdit: json, annotationsEditOnProperty: params.onProperty, annotationsEditAsProperty: params.asProperty, annotationsEditPage: params.page, annotationsEditModalShow:true, annotationsEditMode: params.mode,
      //         annotationsEditAnnotators: params.annotators, annotationsEditState: { loaded: true, loading: false, failed: false}})},
      //         () => this.setState({ annotationsEdit: null, annotationsEditState: {loaded: false, loading: false, failed: true}}) )
      // )
        this.setState({ annotationsEdit: null, annotationsEditModalShow:true, annotationsEditState: { loaded: false, loading: true, failed: false}}, () =>
        previewAnnotator(params.id, params.page)
        .then(json => {
            this.setState({ editGroupId: params.id, annotationsEdit: json, annotationsEditPage: params.page, annotationsEditModalShow:true, annotationsEditMode: params.mode,
              annotationsEditAnnotators: params.annotators, annotationsEditState: { loaded: true, loading: false, failed: false}})},
              () => this.setState({ annotationsEdit: null, annotationsEditState: {loaded: false, loading: false, failed: true}}) )
      )
    } else if (action === 'view-annotations') {
    //   this.setState({ annotationsEdit: null, annotationsEditModalShow:true, annotationsEditState: { loaded: false, loading: true, failed: false}}, () =>
    //   viewValuesAnnotations(this.props.schemaDataset['@id'], params.annotators.map(an => an.uuid), params.onProperty, params.asProperty, params.page, params.mode)
    //   .then(json => {
    //       this.setState({ editGroupId: params.id, annotationsEdit: json, annotationsEditOnProperty: params.onProperty, annotationsEditAsProperty: params.asProperty, annotationsEditPage: params.page, annotationsEditModalShow:true, annotationsEditMode: params.mode,
    //         annotationsEditAnnotators: params.annotators, annotationsEditState: { loaded: true, loading: false, failed: false}})},
    //         () => this.setState({ annotationsEdit: null, annotationsEditState: {loaded: false, loading: false, failed: true}}) )
    // )
      this.setState({ annotationsEdit: null, annotationsEditModalShow:true, annotationsEditState: { loaded: false, loading: true, failed: false}}, () =>
      viewValuesAnnotations(params.id, params.page, params.mode, params.annotators.map(an => an.uuid))
      .then(json => {
          this.setState({ editGroupId: params.id, annotationsEdit: json, annotationsEditPage: params.page, annotationsEditModalShow:true, annotationsEditMode: params.mode,
            annotationsEditAnnotators: params.annotators, annotationsEditState: { loaded: true, loading: false, failed: false}})},
            () => this.setState({ annotationsEdit: null, annotationsEditState: {loaded: false, loading: false, failed: true}}) )
    )
  } else if (action === 'validate-annotations') {
    this.setState({ annotationValidations: {}, annotationValidationsModalShow:true, annotationValidationsState: { loaded: false, loading: true, failed: false}}, () =>
    viewAnnotationValidation(params.id, params.currentPage, params.mode, params.serial, params.navigation, params.requestedPage)
    .then(json => {
        this.setState({ annotationValidations: json, annotationsEditOnProperty: params.onProperty, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}})},
          () => this.setState({ annotationValidations: {}, annotationValidationsState: {loaded: false, loading: false, failed: true}}) )
  )
  } else if (action === 'download-annotation-values') {
    downloadAnnotationValues(params.id, params.mode)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

              response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                  });
            } else {
               Promise.reject(response)
            }},
            () => Promise.reject())
  } else if (action === 'create-filter-validation') {
    if (params.id == null) {
      createFilterAnnotationValidation(params.aegId, params.name, params.filters)
        .then((json) =>
           this.setState({ annotationEditGroups: this.state.annotationEditGroups.map(el => el.id === params.aegId ?
             {...el , filterAnnotationValidations: el.filterAnnotationValidations.slice().concat(json) } : el ).sort(this.validationsSort) }))
    } else {
      updateFilterAnnotationValidation(params.id, params.name, params.filters)
        .then((json) =>
           this.setState({ annotationEditGroups: this.state.annotationEditGroups.map(el => el.id === params.aegId ?
             {...el , filterAnnotationValidations: el.filterAnnotationValidations.filter(fav => fav.id !== params.id).concat(json) } : el ).sort(this.validationsSort) }))

    }
  } else if (action === 'execute-filter-validation') {
    executeFilterAnnotationValidation(params.id)
  } else if (action === 'view-filter-validation') {
     this.setState({ annotationEditExecutions: null, annotationEditExecutionModalShow:true, annotationEditExecutionsState: { loaded: false, loading: true, failed: false}}, () =>
     viewFilterAnnotationValidationExecution(params.id)
     .then(response => {
         response.text().then(text =>
         this.setState({annotationEditExecutions: { text: text },
           annotationEditExecutionsState: { loaded: true, loading: false, failed: false}}))},
           () => this.setState({ annotationEditExecutions: null, annotationEditExecutionsState: {loaded: false, loading: false, failed: true}}) )
   )
  } else if (action === 'publish-filter-validation') {
      publishFilterAnnotationValidation(params.id)
  } else if (action === 'unpublish-filter-validation') {
      unpublishFilterAnnotationValidation(params.id)
  } else if (action === 'create-paged-validation') {
    if (params.id == null) {
      createPagedAnnotationValidation(params.aegId, params.name)
      .then((json) =>
         this.setState({ annotationEditGroups: this.state.annotationEditGroups.map(el => el.id === params.aegId ?
           {...el , pagedAnnotationValidations: el.pagedAnnotationValidations.slice().concat(json) } : el ).sort(this.validationsSort) }))
    } else {

    }
  } else if (action === 'execute-paged-validation') {
    executePagedAnnotationValidation(params.id)
  } else if (action === 'view-paged-validation') {
     this.setState({ annotationEditExecutions: null, annotationEditExecutionModalShow:true, annotationEditExecutionsState: { loaded: false, loading: true, failed: false}}, () =>
     viewPagedAnnotationValidationExecution(params.id)
     .then(response => {
         response.text().then(text =>
         this.setState({annotationEditExecutions: { text: text },
           annotationEditExecutionsState: { loaded: true, loading: false, failed: false}}))},
           () => this.setState({ annotationEditExecutions: null, annotationEditExecutionsState: {loaded: false, loading: false, failed: true}}) )
   )
 } else if (action === 'publish-paged-validation') {
      publishPagedAnnotationValidation(params.id)
  } else if (action === 'unpublish-paged-validation') {
      unpublishPagedAnnotationValidation(params.id)
  } else if (action === 'edit-annotation-validation') {
    // TODO
    // console.log(params);
    // editAnnotationValidation(params.id);
  } else if (action === 'end-annotation-validation') {
    endAnnotationValidation(params.pavid);
  } else if (action === 'publish-checked-annotators') {
      publishAnnotators(this.state.annotators.filter(el => el.selected && isEqual(el.onProperty, params.onProperty) ).map(el => el.id));
  } else if (action === 'unpublish-checked-annotators') {
      unpublishAnnotators(this.state.annotators.filter(el => el.selected && isEqual(el.onProperty, params.onProperty) ).map(el => el.id))
  // } else if (action === 'add-delete-annotation-edit') {
  //     var annotationsEdit = this.state.annotationsEdit.map(el => {return el.value !== params.propertyValue ?
  //         el :
  //         {...el, details: el.details.map(el2 => {return el2.value !== params.annotationValue ?
  //            el2 : {...el2, deleted: !el2.deleted}})}} )
  //     this.setState({annotationsEdit})
  //     addDeleteAnnotationEdit(this.props.schemaUrl, this.state.annotationsEditProperty, params.propertyValue, params.annotationValue)
  } else if (action === 'commit-annotation-edits') {
     commitAnnotationEdits(params.id, params.edits)
     // .then(response =>
       // this.setState({annotationsEditModalShow:false})
     // )
   } else if (action === 'commit-annotation-validation-page') {
     commitAnnotationValidationPage(params.pageid, params.edits, params.lockId)
     .then(response =>
       this.setState({ annotationValidations: {}, annotationValidationsModalShow:true, annotationValidationsState: { loaded: false, loading: true, failed: false}}, () =>
       viewAnnotationValidation(params.id, params.currentPage, params.mode, params.serial, params.navigation, params.requestedPage)
       .then(
         json => {
           if (json.errorMessage === "NO_PAGE_FOUND") {
             let obj = {
               currentPage: params.currentPage,
               data: [],
               errorMessage: json.errorMessage,
               filter: null,
               id: params.id,
               lockId: params.lockId,
               mode: params.mode.split('_')[0]+'_ONLY',
               pagedAnnotationValidationId: params.id,
               pagedAnnotationValidationPageId: params.pageid,
               totalPages: 0
             }
             this.setState({ annotationValidations: obj, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}});
           }
           else {
             this.setState({ annotationValidations: json, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}});
           }
         },
         () => this.setState({ annotationValidations: {}, annotationValidationsState: {loaded: false, loading: false, failed: true}}) )
     )
     )
   // } else if (action === 'publish-validation') {
   //    publishAnnotationEdits(params.id)
  // } else if (action === 'unpublish-validation') {
  //    unpublishAnnotationEdits(params.id)
   } else if (action === 'view-validation') {
       viewAnnotationEditsExecution(params.id)
       .then(response => {
        if (response.status === 204) {
          // this.setState({ lastExecution: { message: "The mapping has not been executed." }})
          return;
        } else if (!response.ok) {
         return "Error";
       } else {
         response.text().then(text =>
           this.setState({ annotationEditExecutions: { text: text }, annotationEditExecutionModalShow:true}))
       }
     })

     this.setState({ annotationEditExecutions: null, annotationEditExecutionModalShow:true, annotationEditExecutionsState: { loaded: false, loading: true, failed: false}}, () =>
     viewAnnotationEditsExecution(params.id)
     .then(response => {
         response.text().then(text =>
         this.setState({annotationEditExecutions: { text: text },
           annotationEditExecutionsState: { loaded: true, loading: false, failed: false}}))},
           () => this.setState({ annotationEditExecutions: null, annotationEditExecutionsState: {loaded: false, loading: false, failed: true}}) )
   )

  } else if (action === 'create-vocabularizer') {
    // console.log(params);
    createVocabularizer(this.props.schemaDataset['@id'], params.onProperty, params.name, params.separator)
        .then((json) =>
            this.setState({ vocabularizers: this.state.vocabularizers.slice().concat(json) })
          )
  } else if (action === 'delete-vocabularizer') {
   deleteVocabularizer(params.id)
      .then(() =>
         this.setState({ vocabularizers: this.state.vocabularizers.filter(el => el.id !== params.id)}))
  } else if (action === 'execute-vocabularizer') {
     executeVocabularizer(params.id)
   } else if (action === 'view-vocabularizer-execution') {
     viewVocabularizerExecution(params.id)
     .then(response => {
      if (response.status === 204) {
        // this.setState({ lastExecution: { message: "The mapping has not been executed." }})
        return;
      } else if (!response.ok) {
       return "Error";
     } else {
       response.text().then(text =>
         this.setState({ annotations: { text: text }, annotationsModalShow:true}))
     }
   })
 } else if (action === 'publish-vocabularizer') {
       publishVocabularizer(params.id)
 } else if (action === 'unpublish-vocabularizer') {
        unpublishVocabularizer(params.id)
 } else if (action === 'index-vocabularizer') {
        indexVocabularizer(params.id)
 } else if (action === 'unindex-vocabularizer') {
       unindexVocabularizer(params.id)
 } else if (action === 'execute-cleanup-vocabularizer') {

   var promises = []

   var vocabulary;
   var equivalences;

   promises.push(
     new Promise(function(resolve, reject) {
       vocabularyVocabularizer(params.id)
         .then((json) =>  {
           vocabulary = json;
           resolve()
         })})
    )
   promises.push(
     new Promise(function(resolve, reject) {
       cleanupVocabularizer(params.id)
         .then((json) =>  {
           equivalences = json;
           resolve()
         })})
    )

     Promise.all(promises)
      .then(() => {
        var map = new Map();
        for (var i in vocabulary) {
          var set = []
          set.push({uri: vocabulary[i]['@id'], text: vocabulary[i]['http://www.w3.org/2000/01/rdf-schema#label'][0]['@value']});
          map.set(vocabulary[i]['@id'], set);
        }

        for (var i in equivalences) {
          for (var j in equivalences[i]) {
            if (j === '0') {
              var set = map.get(equivalences[i][j].uri);
            } else {
              set.push({uri: equivalences[i][j].uri, text: equivalences[i][j].text});
              map.set(equivalences[i][j].uri, set);
            }
          }
        }

        // console.log(map);
        this.setState({vocabulary: map, vocabularyEditModalShow: true})

     })

   }  else if (action === 'index') {
     // index(this.props.schemaUrl, params.onProperty)
     index(this.props.schemaDataset['@id'])
   }  else if (action === 'unindex') {
     unindex(this.props.schemaDataset['@id'])
   }
}

  render() {

    // var index = this.props.indexes.filter(el => el.onProperty[0] === this.props.value['@id']);
    // if (index.length > 0) {
    //   index = index[0]
    // } else {
    //   index = null
    // }

    // var index;
    // var indexed = false;
    // if (Object.keys(this.state.indexes).length) {
    //   index = this.state.indexes;
    //   indexed = true;
    // } else {
    //   index = { indexState : "NOT_INDEXED" }
    // }

    // var control = { '@id': 'control' };

    let context = this.state.schema['@context'];

    return (
      <div>
        {/* }{this.state.schema.length > 0 && */}
        {this.props.schemaDataset &&
        <Container className="grouping groupborder">
          <Row className="header">
            <Col className="mybutton"  md="auto">
              <span className='fa fa-bullseye close editbutton'></span>
            </Col>
            <Col>
              <span className="bold nospace2">{filterByLanguage(this.props.schemaDataset,'http://www.w3.org/2000/01/rdf-schema#label', Localizer.language)}
                            </span>
            </Col>
{/*            {this.state.schemaState.loaded && <Col className="mybutton" md="auto">
              <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
                <Dropdown.Item  onClick={(event) => this.schemaActions('publish-checked-annotators')}>Publish Selected</Dropdown.Item>
                <Dropdown.Item  onClick={(event) => this.schemaActions('unpublish-checked-annotators')}>Unpublish Selected</Dropdown.Item>
                {/*!indexed && <Dropdown.Item  onClick={(event) => this.schemaActions('index')}>Index</Dropdown.Item>*/}
                {/*indexed && <Dropdown.Item  onClick={(event) => this.schemaActions('unindex')}>Unindex</Dropdown.Item>*/}
              {/*</DropdownButton>
            </Col>}*/}
          </Row>
          {/*<div>
            <Row className="tableheader">
            </Row>
            <Row className="tableheader">
              <Col>
                <span>Index</span>
              </Col>
              <Col md="4" className="tablecenter">
                  <Row>
                    <Col>
                      <IndexableState value={index}/>
                    </Col>
                  </Row>
              </Col>
              <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Unindex" onClick={() => this.props.actions('unindex', { onProperty: [this.props.value['@id']] })}><span className="fa fa-trash"></span></Button>
              </Col>
          </Row>

            <SchemaProperty value={control}
                          annotators={this.state.annotators}
                          vocabularizers={this.state.vocabularizers}
                          indexes={this.state.indexes}
                          actions={(action, params) => this.schemaActions(action, params)}
                          queryProperties={this.props.queryProperties}
                          dataAnnotators={this.props.dataAnnotators}
                          vocabularies={this.props.vocabularies}
                          showPropertyData={(path) => this.showPropertyData(this.props.schemaUrl, path)}/>

          </div>*/}

          {this.state.schemaState.loading &&
            <Col className="loader">
            <BarLoader css='spinner' height={6} width={600} color='crimson' loading={true}/>
            </Col>
          }
          {this.state.schemaState.failed &&
            <Col>
            <span className="error">Loading {filterByLanguage(this.props.schemaDataset,'http://www.w3.org/2000/01/rdf-schema#label', Localizer.language)} failed.</span>
            </Col>
          }
        {this.state.schemaState.loaded && this.state.schema.length === 0 &&
          <div className="text-center text-danger">
            <i className="fa fa-exclamation-triangle fa-lg mr-3" aria-hidden="true"></i>
            <span><strong>There are not any Property Annotations open for validation at the moment.</strong></span>
          </div>
        }
        {this.state.schemaState.loaded && this.state.topParents.map((el,index) =>
          <Container key={index} className={"grouping groupborder" + (((el.hasOwnProperty(this.state.keys.propertyPartitionKey) && el[this.state.keys.propertyPartitionKey].length > 0) || (el.hasOwnProperty(this.state.keys.classPartitionKey) && el[this.state.keys.classPartitionKey].length > 0))  ? "": "short")}>
            <Row  className={"red header" + (((el.hasOwnProperty(this.state.keys.propertyPartitionKey) && el[this.state.keys.propertyPartitionKey].length > 0) || (el.hasOwnProperty(this.state.keys.classPartitionKey) && el[this.state.keys.classPartitionKey].length > 0))  ? "": "short")}>
              <Col>
                <Container>
                  <Row>
                    <span>{el.hasOwnProperty(this.state.keys.propertyKey) ? resolveJsonLdUri(el[this.state.keys.propertyKey],context) : resolveJsonLdUri(el[this.state.keys.classKey], context)}</span>
                  </Row>
                  {el['http://www.w3.org/2000/01/rdf-schema#label'] &&
                  <Row>
                      <span className="thin label">{filterByLanguage(el,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                  </Row>}
                </Container>
              </Col>
              <Col className="thin" md="auto">
                <span>{el[this.state.keys.entitiesKey]}</span>
              </Col>
            </Row>
            {el['http://www.w3.org/2000/01/rdf-schema#label'] &&
            <Row>
              <Col>
                <span className="label">{filterByLanguage(el,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
              </Col>
            </Row>}
            <Row>
          {el.hasOwnProperty(this.state.keys.propertyPartitionKey) &&  objectToArray(el[this.state.keys.propertyPartitionKey]).map((el2,index2) =>
        <SchemaProperty key={index2} value={el2}
                        schema={this.state.schema}
                        keys={this.state.keys}
                        mode={this.props.mode}
                        language={this.props.language}
                        annotators={this.state.annotators}
                        annotationEditGroups={this.state.annotationEditGroups}
                        validationProgress={this.state.validationProgress}
                        vocabularizers={this.state.vocabularizers}
                        indexes={this.state.indexes}
                        actions={(action, params) => this.schemaActions(action, params)}
                        queryProperties={this.props.queryProperties}
                        dataAnnotators={this.props.dataAnnotators}
                        preprocessFunctions={this.props.preprocessFunctions}
                        vocabularies={this.props.vocabularies}
                        valueActions={(action, path, mode) => this.valueActions(action, this.props.schemaDataset['@id'], [{type:"class", uri: el['@id']}].concat(path), mode)}/>
        )}
        {el.hasOwnProperty(this.state.keys.classPartitionKey) &&  objectToArray(el[this.state.keys.classPartitionKey]).map((el2,index2) =>
        <SchemaProperty key={index2} value={el2}
                        schema={this.state.schema}
                        keys={this.state.keys}
                        mode={this.props.mode}
                        language={this.props.language}
                        annotators={this.state.annotators}
                        annotationEditGroups={this.state.annotationEditGroups}
                        validationProgress={this.state.validationProgress}
                        vocabularizers={this.state.vocabularizers}
                        indexes={this.state.indexes}
                        actions={(action, params) => this.schemaActions(action, params)}
                        queryProperties={this.props.queryProperties}
                        dataAnnotators={this.props.dataAnnotators}
                        preprocessFunctions={this.props.preprocessFunctions}
                        vocabularies={this.props.vocabularies}
                        valueActions={(action, path, mode) => this.valueActions(action, this.props.schemaDataset['@id'], [{type:"class", uri: el['@id']}].concat(path), mode)}/>
        )}
        </Row>
        </Container>
      )}

        {this.state.valuesModalShow && <PropertyValuesModal show={this.state.valuesModalShow}
                             value={this.state.values}
                             state={this.state.valuesState}
                             path={this.state.valuesPath}
                             language={this.props.language}
                             valueActions={(action, path, mode) => this.valueActions(action, this.props.schemaDataset['@id'], path, mode)}
                             onClose={() => this.setState({ valuesModalShow: false })}/>}

        {this.state.annotationsModalShow && <ResultsModal show={this.state.annotationsModalShow}
                      state={this.state.annotationsState}
                      execution={this.state.annotations}
                      onClose={() => this.setState({ annotationsModalShow: false })}/>}

        {this.state.annotationEditExecutionModalShow && <ResultsModal show={this.state.annotationEditExecutionModalShow}
                      state={this.state.annotationEditExecutionsState}
                      execution={this.state.annotationEditExecutions}
                      onClose={() => this.setState({ annotationEditExecutionModalShow: false })}/>}

        {this.state.annotationsEditModalShow ? <AnnotationValuesModal show={this.state.annotationsEditModalShow}
                             state={this.state.annotationsEditState}
                             value={this.state.annotationsEdit}
                             onProperty={this.state.annotationsEditOnProperty}
                             asProperty={this.state.annotationsEditAsProperty}
                             mode={this.state.annotationsEditMode}
                             page={this.state.annotationsEditPage}
                             id={this.state.editGroupId}
                             // dataAnnotators={this.props.dataAnnotators}
                             annotators={this.state.annotationsEditAnnotators}
                             language={this.props.language}
                             // edits={this.annotationEdits}
                             actions={(action, params) => this.schemaActions(action, params)}
                             onClose={() => this.setState({ annotationsEditModalShow: false })}/> : null }

         {this.state.annotationValidationsModalShow
           ? <ValidationModal
                show={this.state.annotationValidationsModalShow}
                state={this.state.annotationValidationsState}
                value={this.state.annotationValidations}
                onProperty={this.state.annotationsEditOnProperty}
                datasetName={filterByLanguage(this.props.schemaDataset,'http://www.w3.org/2000/01/rdf-schema#label', Localizer.language)}
                // asProperty={this.state.annotationsEditAsProperty}
                // mode={this.state.annotationsEditMode}
                // page={this.state.annotationsEditPage}
                // id={this.state.editGroupId}
                // annotators={this.state.annotationsEditAnnotators}
                language={this.props.language}
                actions={(action, params) => this.schemaActions(action, params)}
                onClose={() => this.setState({ annotationValidationsModalShow: false })}/>
           : null }

         <VocabularyValuesModal show={this.state.vocabularyEditModalShow}
                              value={this.state.vocabulary}
                              // onProperty={this.state.annotationsEditProperty}
                              // asProperty={this.state.annotationsAsProperty}
                              // id={this.state.editGroupId}
                              // edits={this.annotationEdits}
                              actions={(action, params) => this.schemaActions(action, params)}
                              onClose={() => this.setState({ vocabularyEditModalShow: false })}/>

          {this.state.deleteModalShow && <DeleteModal show={this.state.deleteModalShow}
                                  command={this.state.deleteModalCommand}
                                  params={this.state.deleteModalParams}
                                  actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.schemaActions(command + '-ok', params): null)} }
                                  />}

</Container>}
      </div>
    );
  }
}



export default PublishedSchema;
