import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import BarLoader from "react-spinners/BarLoader";
import IdleTimer from "react-idle-timer";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import Countdown, { zeroPad } from 'react-countdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import LoadingComponent from '../../LoadingComponent';
import { labelResource, removeValidationPageLock, getValidationProgress } from "../../../utils/APIUtils.js";
import { filterByLanguage } from "../../../utils/functions.js";
import { Localizer } from "../../../config/localizer.js";

const idleTimeout = 1000 * 60 * 10;    // 10 minutes
const toastTimeout = 1000 * 10;        // 10 seconds
const sessionTimeout = 1000 * 60 * 30; // 30 minutes

export class ValidationModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.createState(props);
    this.state.labelsLoaded = false;
    this.state.idleUser = false;
    this.state.idleToastId = null;
    this.state.expirationTime = Date.now() + sessionTimeout;
    this.state.lockReleased = false;
    this.state.totalValidations = 0;
    this.state.totalAnnotations = -1;
    this.state.modalTitle = '';
    this.resolveLabels();

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.handlePageSelection = this.handlePageSelection.bind(this);
  }

  handleOnAction (event) {
    this.setState({idleUser: false});
    if (this.state.idleToastId) {
      toast.dismiss(this.state.idleToastId);
      this.setState({idleToastId: null});
    }
  }

  handleOnActive (event) {
    this.setState({idleUser: false});
  }

  handleOnIdle (event) {
    if (this.state.lockReleased) {
      return;
    }
    this.setState({idleUser: true});

    let id = toast.error(
      "You have been idle for 10 minutes. Become active to keep your changes.",
      {
        autoClose: toastTimeout,
        hideProgressBar: false,
        progress: undefined,
        onClose: () => this.state.idleUser && this.modalCancel()
      }
    );
    this.setState({idleToastId: id});
  }

  handlePageSelection(event) {
    event.preventDefault();
    let page = this.selectedPage;

    if (typeof page.value !== "undefined") {
      if (page.value.length > 0) {
        let num = parseInt(page.value);

        if (page.value > 0 && page.value <= this.props.value.totalPages) {
          this.gotoPage(this.props.value.currentPage, num, null, this.props.value.mode+"_SPECIFIC_PAGE");
        }
        else {
          toast.error("The page you entered does not exist.");
          this.selectedPage.value = '';
        }
      }
    }
  }

  gotoPage(currentPage, requestedPage, navigation, mode) {
    if (this.duplicateAnnotations) {
      toast.error("There are duplicate annotations. Please fix them.");
      return;
    }

    let changes = this.prepareCommit();
    let obj = {
      id: this.props.value.id,
      pageid: this.props.value.pagedAnnotationValidationPageId,
      currentPage: currentPage,
      lockId: this.props.value.lockId,
      edits: changes
    };

    if (requestedPage) {
      obj.requestedPage = requestedPage;
      obj.serial = false;
    }
    else {
      obj.navigation = navigation;
      obj.serial = true;
    }

    if (mode) {
      obj.mode = mode;
    }
    else if (this.props.value.filter && this.props.value.filter !== "ANNOTATED_ONLY_SERIAL" && currentPage !== requestedPage) {
      obj.mode = this.props.value.filter;
    }
    else {
      obj.mode = navigation ? this.props.value.mode + '_SERIAL' : this.props.value.mode + '_SPECIFIC_PAGE';
    }

    this.props.actions('commit-annotation-validation-page', obj);
  }

  modalCancel() {
    if (!this.props.value.pagedAnnotationValidationId || this.state.lockReleased) {
      this.props.onClose();
      return;
    }
    removeValidationPageLock(this.props.value.pagedAnnotationValidationId, this.props.value.mode, this.props.value.currentPage)
      .then(() => {
        this.setState({lockReleased: true});
        toast.dismiss();
        this.props.onClose();
      })
      .catch(error => {
        console.error(error);
        toast.error("Releasing the validation-page lock failed.");
        this.props.onClose();
      });
  }

  modalClose() {
    let changes = this.prepareCommit();
    if (changes.length > 0) {
      if (window.confirm('If you close the modal, all unsaved changes will be discarded')) {
        this.modalCancel();
      }
    }
    else {
      this.modalCancel();
    }
  }

  get duplicateAnnotations() {
    let elements = document.getElementsByClassName("is-invalid");
    if (elements.length === 0) {
      return false;
    }
    return true;
  }

  get filterTitle() {
    if (this.props.value.filter) {
      if (this.props.value.filter === "ANNOTATED_ONLY_SERIAL") {
        return 'All';
      }
      else if (this.props.value.filter === "ANNOTATED_ONLY_NOT_VALIDATED") {
        return 'Not Validated';
      }
      else if (this.props.value.filter === "ANNOTATED_ONLY_NOT_COMPLETE") {
        return 'Partially Validated';
      }
    }
    return 'All';
  }

  resolveLabels() {

    var labelPromises = [];
    var _this = this;

    var newElements = this.state.elements.slice();

    // for (const i in _this.state) {
    for (const i in _this.state.elements) {
      // console.log(i);
      // if (i === 'keys') {
      //   continue;
      // }
      // for (const j in _this.state[i].details) {
      for (const j in _this.state.elements[i].details) {
        // const current = _this.state[i].details[j];
        const current = _this.state.elements[i].details[j];
        labelPromises.push(
          new Promise(function(resolve, reject) {
            labelResource(current.value)
              .then(json =>  {
                // _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j !== index) ? d : {...d, label: json})}}); // it should be != do not change
                // var obj = {..._this.state.elements[i], details: _this.state.elements[i].details.map((d, index) => (j !== index) ? d : {...d, label: json})};
                // _this.setState({ elements: _this.state.elements.slice(0, i).concat(obj).concat(_this.state.elements.slice(i + 1))});
                newElements[i].details[j] = { ...newElements[i].details[j], label:json};
                resolve();
              }).catch(() => resolve())
          })
        )
        if (current.value2 !== null) {
          labelPromises.push(
            new Promise(function(resolve, reject) {
              labelResource(current.value2)
                .then((json) =>  {
                  // _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j !== index) ? d : {...d, label2: json})}}); // it should be != do not change
                  // var obj = {..._this.state.elements[i], details: _this.state.elements[i].details.map((d, index) => (j !== index) ? d : {...d, label2: json})};
                  // _this.setState({ elements: _this.state.elements.slice(0, i).concat(obj).concat(_this.state.elements.slice(i + 1))});
                  newElements[i].details[j] = { ...newElements[i].details[j], label:json};
                  resolve();
                }).catch(() => resolve())
            })
          )
        }
      }
    }

    Promise.all(labelPromises).then(() => {
      if (newElements.length > 0 || this.props.value.errorMessage) {
        this.setState({ labelsLoaded: true });
      }
      else {
        this.setState({ labelsLoaded: false });
      }
      this.setState({elements: newElements});
    });
  }

  hightlightArray(input, array) {
    var str = '';
    if (input.lexicalForm) {
      str = input.lexicalForm
    } else if (input.iri) {
      str = input.iri
    }
    var result = ""
    if (!array || array.length === 0) {
      result = str
    } else {
      var prev = 0;
      var index = 0;
      var last = -1;
      while (index < array.length) {
        if (array[index].start === undefined || array[index].end === undefined || array[index].start < 0 || array[index].end < 0)  {
          index++;
          continue;
        }
        last = index;
        var s = array[index].start;
        var e = array[index].end;
        result += str.substr(prev,s - prev);
        for (var i = index + 1; i < array.length;) {
          var nextElement = array[i];
          if (nextElement.start && nextElement.end && e > nextElement.start) {
            if (nextElement.end >= e) {
              e = nextElement.end;
            }
            index++;
            i++;
          } else {
            break;
          }
        }
        result += '<span class="highlight">' +
        str.substr(s, e - s) +
        '</span>';
        index++;
        prev = e;
      }
      if (last >= 0) {
        result += str.substr(array[last].end);
      }
      else {
        result = str;
      }
    }
    return "<code><pre class='mb-0'>" +  result + (input.language ? '<br/><span class="litlanguage">' + input.language  + '</span>': "") + "</pre></code>";
 }

  createState(props) {
      var state = {};
      // var keys = [];

      var elements = [];

      for (const i in props.value.data) {
        // keys.push(i);
        var details = [];
        for (const j in props.value.data[i].details) {
          var el = {...props.value.data[i].details[j]}
          if (el.state === 'ADD') {
            el = {...el, originalValue: el.value, originalValue2: el.value2}
          }
          if (el.state) {
            el = {...el, originalState: el.state}
          }
          // el = {...el, othersRejected: el.othersRejected, othersAccepted: el.othersAccepted, count: el.count}

          details.push(el);
        }
        // state['element' + i] = { value: props.value[i].onValue, details,  deleted: [], accepted: [] };
        elements.push({ value: props.value.data[i].onValue, count: props.value.data[i].count, details,  deleted: [], accepted: [] })
      }

      // state.keys = keys;
      state.elements = elements;
      return state;
  }

  annotationStyle(state) {
    if (state === "ACCEPT") {
      return "td-accepted";
    } else if (state === "REJECT") {
      return "td-deleted";
    } else {
      return "td-normal";
    }
  }

  newAnnotation(event, index) {
    // var obj = this.state['element' + index];
    var obj = this.state.elements[index];
    var add = {value:'', state:'ADD', othersAccepted: 0, othersRejected: 0}
    obj = { ...obj, details : obj.details.concat(add)}
    // this.setState({ ['element' + index]: obj });
     this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  editAnnotation(event, index, order) {
    let foundDuplicate = false;
    let element = document.getElementById("el-"+index+"_ann-"+order);
    this.state.elements[index].details.forEach((ann, i) => {
      if ( (ann.value === event.target.value) ) {
        foundDuplicate = true;
        element.classList.add("is-invalid");
      }
    });
    if (!foundDuplicate) {
      element.classList.remove("is-invalid");
    }

    // var obj = this.state['element' + index]
    var obj = this.state.elements[index];
    obj = { ...obj, details : obj.details.map((el,i) => {
      return i !== order ? el : { ...el, value: event.target.value} })
    }
    // this.setState({  ['element' + index]: obj  });
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  deleteAnnotation(index, ann, order) {
    // var obj = this.state['element' + index]
    var obj = this.state.elements[index]
    // var ann = obj.details[order];
    if (ann.state === 'ADD') {
      // obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), deleted: obj.deleted.concat(obj.details[order])}
        obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), deleted: obj.deleted.concat(obj.details.filter((el,i) => el.id && el.value === ann.value ))}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         // return i !== order ? el : el.state === 'REJECT' ? {...el, state: 'UNREJECT'} : {...el, state: 'REJECT'} })
         return el.value !== ann.value ? el : el.state === 'REJECT' ? {...el, state: 'UNREJECT'} : {...el, state: 'REJECT'} })
      }
    }
    // this.setState({  ['element' + index]: obj  })
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  acceptAnnotation(index, ann) {
    // var obj = this.state['element' + index];
    var obj = this.state.elements[index];
    // var ann = obj.details[order];
    if (ann.state === 'ADD') {
      // obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), accepted: obj.accepted.concat(obj.details[order])}
      obj = { ...obj, details : obj.details.filter((el,i) =>  el.value !== ann.value ), accepted: obj.accepted.concat(obj.details.filter((el,i) => el.value === ann.value ))}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         // return i !== order ? el : el.state === 'ACCEPT' ? {...el, state: 'UNACCEPT'} : {...el, state: 'ACCEPT'} })
         return  el.value !== ann.value ? el : el.state === 'ACCEPT' ? {...el, state: 'UNACCEPT'} : {...el, state: 'ACCEPT'} })
     }
    }
    // this.setState({  ['element' + index]: obj  }, )
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  prepareCommit() {
    // console.log(this.state.elements)
    var changes = [];
    // for (const i in this.state.keys) {
    for (const i in this.state.elements) {
      let entry = {};
      // var obj = this.state['element' + i];
      var obj = this.state.elements[i];

      let edits = [];
      for (const j in obj.details) {
        if (obj.details[j].state === 'ADD') {
          if (obj.details[j].originalState === 'ADD' && obj.details[j].value !== obj.details[j].originalValue) {
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ADD', start: -1, end: -1, count: obj.details[j].count} ) // change annotationEdit value
          } else if (!obj.details[j].originalState && obj.details[j].value.length > 0) {
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ADD', start: -1, end: -1, count: obj.count} ) // add annotationEdit
          }
        } else if (obj.details[j].state === 'UNREJECT') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'REJECT') {
            edits.push( {id: obj.details[j].id, count: obj.details[j].count}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'UNACCEPT') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'REJECT') {
            edits.push( {id: obj.details[j].id, count: obj.details[j].count}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'REJECT') {
          if (obj.details[j].originalState === 'ACCEPT') {
            edits.push( {id: obj.details[j].id, count: obj.details[j].count})
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'REJECT', start: obj.details[j].start, end: obj.details[j].end, count: obj.details[j].count})
          } else if (!obj.details[j].originalState) {
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'REJECT', start: obj.details[j].start, end: obj.details[j].end, count: obj.details[j].count})
          }
        } else if (obj.details[j].state === 'ACCEPT') {
          if (obj.details[j].originalState === 'REJECT') {
            edits.push( {id: obj.details[j].id, count: obj.details[j].count})
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ACCEPT', start: obj.details[j].start, end: obj.details[j].end, count: obj.details[j].count})
          } else if (!obj.details[j].originalState) {
            edits.push( {id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ACCEPT', start: obj.details[j].start, end: obj.details[j].end, count: obj.details[j].count})
          }
        }
      }

      for (const j in obj.deleted) {
        edits.push( {id: obj.deleted[j].id, count: obj.deleted[j].count})      //delete added annotationEdit
      }

      if (edits.length > 0) {
        entry.propertyValue = obj.value;
        entry.edits = edits;
        changes.push(entry);
      }
    }

    return changes;
  }

  componentWillReceiveProps(props) {
    if (this.props.value.errorMessage !== props.value.errorMessage) {
      if (props.value.errorMessage === "redirect") {
        if (props.value.filter === "ANNOTATED_ONLY_SPECIFIC_PAGE" || props.value.filter === "UNANNOTATED_ONLY_SPECIFIC_PAGE") {
          toast.error("The requested page is not free");
        }
        else {
          toast.error("No free pages in this direction");
        }
      }
      else if (props.value.errorMessage === "NO_PAGE_FOUND") {
        toast.error("There are no pages with the selected criteria");
      }
    }

    if (this.selectedPage) {
      this.selectedPage.value = '';
    }

    var update = false;
    if (this.props.value.currentPage !== props.value.currentPage || this.props.value.mode !== props.value.mode) {
      update = true;
    }
    if ( this.props.state === null ||
         (this.props.state !== null &&
           (this.props.state.loaded !== props.state.loaded || this.props.state.loading !== props.state.loading || this.props.state.failed !== props.state.failed) ) ) {
      update = true
    }
    if (update) {
      this.setState( this.createState(props), ()=> this.resolveLabels() );

      let propLst = props.onProperty.split("/");
      let propName = propLst[propLst.length - 1];
      this.setState({ expirationTime: Date.now()+sessionTimeout, modalTitle: `${props.datasetName} (${propName.charAt(0).toUpperCase() + propName.slice(1)})` });

      if (props.value.pagedAnnotationValidationId) {
        getValidationProgress(props.value.pagedAnnotationValidationId)
        .then(response => {
          this.setState({totalValidations: response.totalValidations, totalAnnotations: response.totalAnnotations, totalAdded: response.totalAdded});
        })
        .catch(error => {
          console.error(error);
        });
      }
    }
  }

  componentWillUnmount() {
    toast.dismiss();
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  render() {
    return (
      <div>
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        timeout={idleTimeout}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      />
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        closeOnClick={false}
        pauseOnHover={false}
        draggable
      /> */}
      <Modal
        size="xl"
        show={this.props.show}
        onHide={() => this.modalClose()}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Validations: <span className="font-size-4 font-weight-normal">{this.state.modalTitle}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="validation-modal pb-0 px-0">
          {this.props.state.loading &&
            <Col className="loader m-4 d-flex justify-content-center">
              <BarLoader
                css='spinner'
                height={6}
                width={200}
                color='crimson'
                loading={true}/>
            </Col>
          }
          {!this.state.labelsLoaded &&
            <div className="blur-layer">
              <LoadingComponent />
            </div>
          }
          {!this.props.state.loading &&
            <React.Fragment>
            <Row className="bottomrow modalFilters">
              {/*
                <React.Fragment>
                  <Col md="auto pr-1">
                    <Button type="button" className="nextbutton" aria-label="Previous" onClick={() => this.gotoPage(true, this.props.pageHistory[0], this.props.value.mode, false, '', null, '')}>
                      <span className="fa fa-chevron-left"></span>
                    </Button>
                  </Col>
                  <Col md="auto pl-1 backButtonLabel">
                    Previous
                  </Col>
                </React.Fragment>
              */}
              <Col className="align-self-center ml-4">
                <strong>Remaining time: </strong>
                <Countdown
                  date={this.state.expirationTime}
                  renderer={({ hours, minutes, seconds, completed }) => <span className="ml-1">{zeroPad(minutes)}:{zeroPad(seconds)}</span>}
                  onComplete={() => this.modalCancel()}
                />
              </Col>
              {this.state.totalAnnotations > 0 &&
                <Col className="position-absolute align-self-center text-center">
                  <strong className="mr-1">Validations:</strong><span className="mr-3">{this.state.totalValidations}</span>
                  <strong className="mr-1">Annotations:</strong><span className="mr-3">{this.state.totalAnnotations}</span>
                  <strong className="mr-1">Additions:</strong><span>{this.state.totalAdded}</span><br/>
                  <strong className="mr-1">Progress:</strong><span>{this.state.totalAnnotations > 0 ? (100 * this.state.totalValidations / this.state.totalAnnotations).toFixed(2) : 0}%</span>
                </Col>
              }
              <Col md="auto">
                <ButtonGroup className="annotationFilters pr-3">
                  {this.props.value.mode==='ANNOTATED_ONLY' &&
                    <DropdownButton
                      id="extra-filters"
                      title={this.filterTitle}
                      variant="light"
                      className="mr-1"
                    >
                      <OverlayTrigger key="overlay-all" placement="left" delay={50} overlay={<Tooltip id="tooltip-all">Navigate through all annotated pages</Tooltip>}>
                        <Dropdown.Item as="button" className="py-2" key="filter-none" active={!this.props.value.filter || this.props.value.filter==='ANNOTATED_ONLY_SERIAL'} onClick={() => this.gotoPage(0, null, "RIGHT", "ANNOTATED_ONLY_SERIAL")}>
                          <span>All</span>
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger key="overlay-not-validated" placement="left" delay={50} overlay={<Tooltip id="tooltip-not-validated">Navigate through pages with zero validations</Tooltip>}>
                        <Dropdown.Item as="button" className="py-2" key="filter-not-validated" active={this.props.value.filter==='ANNOTATED_ONLY_NOT_VALIDATED'} onClick={() => this.gotoPage(0, null, "RIGHT", "ANNOTATED_ONLY_NOT_VALIDATED")}>
                          <span>Not Validated</span>
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger key="overlay-not-completed" placement="left" delay={50} overlay={<Tooltip id="tooltip-not-completed">Navigate through pages that contain validations, but not for all annotations</Tooltip>}>
                        <Dropdown.Item as="button" className="py-2" key="filter-not-completed" active={this.props.value.filter==='ANNOTATED_ONLY_NOT_COMPLETE'} onClick={() => this.gotoPage(0, null, "RIGHT", "ANNOTATED_ONLY_NOT_COMPLETE")}>
                          <span>Partially Validated</span>
                        </Dropdown.Item>
                      </OverlayTrigger>
                    </DropdownButton>
                  }
                  <Button variant={this.props.value.mode==='ANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.gotoPage(0, null, "RIGHT", "ANNOTATED_ONLY_SERIAL")}>Annotated</Button>
                  <Button variant={this.props.value.mode==='UNANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.gotoPage(0, null, "RIGHT", "UNANNOTATED_ONLY_SERIAL")}>Non Annotated</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <ProgressBar
                className="progress-line w-100 mx-1"
                now={this.state.totalAnnotations > 0 ? (100 * this.state.totalValidations / this.state.totalAnnotations).toFixed(2) : 0}
              />
            </Row>
            </React.Fragment>
          }
          {this.props.state.failed &&
            <div className="text-center py-5">
              <span className="error">Loading values failed.</span>
            </div>
          }
          {this.props.value.errorMessage === "NO_PAGE_FOUND" &&
          <div className="text-center py-5">
            <span className="error">We couldn't find a page with the selected criteria.<br/>Please select a new mode.</span>
          </div>
          }
          {this.props.value.errorMessage !== "NO_PAGE_FOUND" && this.props.state.loaded &&
            <div className="scrollContainer">
              <div className="scroll">
                {this.state.elements.map((el,elindex) =>
                  <Row className="grouping bottomrow"  key={elindex}>
                    <Col className="property-value align-items-center pr-1">
                      <div dangerouslySetInnerHTML={{__html: this.hightlightArray(el.value, el.details)}}></div>
                    </Col>
                    <Col md="auto align-items-center pt-2">
                      {el.count}
                    </Col>
                    <Col className="pl-1 pr-2">
                      <Container>
                      {el.details.map((ann,annindex) =>
                        <Row key={annindex} className={"td-row align-items-center align-self-center " + (ann.othersRejected > ann.othersAccepted ? " reject-bias" : (ann.othersAccepted > ann.othersRejected ? " accept-bias" : "")) }>
                        {ann.state !== 'ADD' ?
                          <Col md={2}>
                            <Button type="button" className={ann.state==='REJECT' ? "deleteeditbutton selected" : "deleteeditbutton"} aria-label="Delete" onClick={(event) => this.deleteAnnotation(elindex, ann)}>
                              <span className={ann.state==='REJECT' ? "fa fa-times-circle" : "fa fa-times"}></span>
                            </Button>
                            <Button type="button" className={ann.state==='ACCEPT' ? "approveButton selected" : "approveButton"} aria-label="Approve" onClick={(event) => this.acceptAnnotation(elindex, ann)}>
                              <span className={ann.state==='ACCEPT' ? "fa fa-check-circle" : "fa fa-check"}></span>
                            </Button>
                          </Col> :
                          <React.Fragment>
                          {(ann.state === 'ADD' && ann.othersAccepted === 0 && ann.othersRejected === 0) ?
                            <Col md={2}>
                            <Button type="button" className="deleteeditbutton" aria-label="Delete" onClick={(event) => this.deleteAnnotation(elindex, ann, annindex)}>
                              <span className="fa fa-trash-o"></span>
                            </Button>
                            </Col> :
                          <Col md={2}></Col>}
                          </React.Fragment>}

                          {ann.state !== 'ADD' || ann.othersAccepted > 0 || ann.othersRejected > 0 ?
                            <Col>
                              <Row>
                              {ann.value2 ?
                                <Col>
                                  <Row>
                                    <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={ann.state === "REJECT" ? "td-deleted" : "td-normal"}>{ann.value}</span></a> -
                                  </Row>
                                  <Row>
                                    <a href={ann.value2} rel='noreferrer noopener' target="_blank"><span className={ann.state === "REJECT" ? "td-deleted" : "td-normal"}>{ann.value2}</span></a>
                                  </Row>
                                </Col>
                              :
                                <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={this.annotationStyle(ann.state)}>{ann.value}</span></a>
                              }
                              </Row>
                              {(ann.label && ann.label[0]) &&
                                <Row>
                                <span className="td-label">{filterByLanguage(ann.label[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                                {(ann.label2 && ann.label2[0]) &&
                                 <span className="td-label"> - {filterByLanguage(ann.label2[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>}
                                </Row>}
                            </Col>
                          :
                            <Col className="pl-0">
                              <Row>
                                <Col fluid="md" className="pr-0">
                                  <Form.Control
                                    id={"el-"+elindex+"_ann-"+annindex}
                                    value={ann.value}
                                    onChange={(event) => this.editAnnotation(event, elindex, annindex)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    The annotation already exists.
                                  </Form.Control.Feedback>
                                </Col>
                                <Col md="auto align-self-center px-3">
                                  <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className="fa fa-link"/></a>
                                </Col>
                              </Row>
                            </Col>
                          }
                        </Row>
                      )}
                      </Container>
                    </Col>
                    <Col md="auto align-self-center ml-0 pl-0 pr-3">
                      <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.newAnnotation(event,elindex)}><span className="fa fa-plus"></span></Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          }
        </Modal.Body>

        {this.props.value.errorMessage !== "NO_PAGE_FOUND" && this.state.labelsLoaded &&
        <Modal.Footer>
          <Row className="modalFooter">
            <Col md="auto">
              <Button variant="outline-danger" onClick={() => this.modalCancel()}>
                Cancel
              </Button>
            </Col>
            <Col md="auto" className="modalPagination ml-1">
              <OverlayTrigger key="overlay-previous" placement="left" defaultShow={false} delay={100} overlay={<Tooltip id="tooltip-previous">Previous available page</Tooltip>}>
                <Button
                  type="button"
                  disabled={this.props.value.currentPage <= 1}
                  variant={this.props.value.currentPage <= 1 ? "outline-light" : "light"}
                  aria-label="Previous"
                  className="nextbutton"
                  onClick={() => this.gotoPage(this.props.value.currentPage, null, "LEFT", null)}
                >
                  <span className="fa fa-angle-double-left"></span>
                </Button>
              </OverlayTrigger>
              <div className="align-self-center mx-2">
                Page
              </div>
              <Form id="page-selector" onSubmit={this.handlePageSelection}>
                <Form.Row>
                  <Col>
                    <Form.Control
                      placeholder={this.props.value.currentPage}
                      as="input"
                      maxLength={3}
                      className="pageSelector"
                      ref={node => (this.selectedPage = node)}
                    />
                  </Col>
                </Form.Row>
              </Form>
              <div className="align-self-center mx-2">
                of <strong>{this.props.value.totalPages}</strong>
              </div>
              <OverlayTrigger key="overlay-next" placement="right" defaultShow={false} delay={100} overlay={<Tooltip id="tooltip-next">Next available page</Tooltip>}>
                <Button
                  type="button"
                  disabled={this.props.value.currentPage === this.props.value.totalPages}
                  variant={this.props.value.currentPage === this.props.value.totalPages ? "outline-light" : "light"}
                  aria-label="Next"
                  className="nextbutton"
                  onClick={() => this.gotoPage(this.props.value.currentPage, null, "RIGHT", null)}
                >
                  <span className="fa fa-angle-double-right"></span>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col md="auto">
              <Button
                disabled={!!this.duplicateAnnotations}
                variant="primary"
                onClick={() => this.gotoPage(this.props.value.currentPage, this.props.value.currentPage, null, null)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
        }
      </Modal>
      </div>
  )}
}

export default ValidationModal;
