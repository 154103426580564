import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';

const sRequest = (options) => {
  const headers = new Headers()

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
};

const sjRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
};

const jsRequest = (options) => {
  const headers = new Headers()

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response =>
      response.ok ? response.json() : Promise.reject(response),
      () => Promise.reject()
    )
};

const mfRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'multipart/form-data',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response =>
      response.ok ? response.json() : Promise.reject(response),
      () => Promise.reject()
    )
};

const jRequest = (options) => {
  return fetch(options.url, options)
    .then(response =>
      response.ok ? response.json() : Promise.reject(response),
      () => Promise.reject()
    )
};

export const request = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response =>
      response.ok ? response.json() : Promise.reject(response),
      () => Promise.reject()
    )
};

export const jjrequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response =>
      response.ok ? response :
        () => Promise.reject()
    )
};

const textrequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'text/plain',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
  // .then(response => {
  //   console.log("RESPONSE");
  //   console.log(response);
  //     response.text().then(text => {
  //         if(!response.ok) {
  //             return Promise.reject(text);
  //         }
  //         // console.log(text);
  //         return text;
  //     })}
  // );
};

const textRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'text/plain',
  })

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
};

export function signin(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export function register(registerRequest) {
  return request({
    url: API_BASE_URL + "/users/create",
    method: 'POST',
    body: JSON.stringify(registerRequest)
  });
}

export function resetPassword(email) {
  return request({
    url: API_BASE_URL + "/users/resetPasswordRequest?email=" + email,
    method: 'POST',
  });
}

export function savePassword(newPassRequest) {
  return request({
    url: API_BASE_URL + "/users/savePassword",
    method: 'POST',
    body: JSON.stringify(newPassRequest)
  });
}

export function userFieldChanged(changeRequest) {
  return request({
    url: API_BASE_URL + "/users/me",
    method: 'PUT',
    body: JSON.stringify(changeRequest)
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/me",
    method: 'GET'
  });
}

export function getUserDetails() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/get",
    method: 'GET'
  });
}


export function getValidatorsOfEditor() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/getValidatorsOfEditor",
    method: 'GET',
  });
}

export function removeValidator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/removeValidator?validatorId=" + id,
    method: 'POST',
  });
}

export function getEditorsOfValidator() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/getEditorsOfValidator",
    method: 'GET',
  });
}

export function getPublicEditors() {
  return request({
    url: API_BASE_URL + "/users/getPublicEditors",
    method: 'GET',
  });
}

export function joinEditor(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/users/joinEditor?editorId=" + id,
    method: 'POST'
  });
}

export function getCurrentDatabase() {
  return request({
    url: API_BASE_URL + "/database/current",
    method: 'GET'
  });
}

export function getMappings(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/mappings/getAll?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function getMapping(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/mappings/get/" + id,
    method: 'GET'
  })
}


export function createMapping(datasetId, type, name, d2rml, data, parameters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

   var body = { name: name, d2rml: d2rml }

   if (parameters && parameters != null) {
     body = { ...body, parameters: parameters }
   }
  //
  // return request({
  //   url: API_BASE_URL + "/mappings/create?type=" + type + "&datasetId=" + datasetId,
  //   method: 'POST',
  //   body: JSON.stringify(body),
  // })

  var formData = new FormData();
  formData.append('file', data);
  formData.append('body', JSON.stringify(body));


   return jsRequest({
     url: API_BASE_URL + "/mappings/create?type=" + type + "&datasetId=" + datasetId,
     method: 'POST',
     body: formData,
   })
}

export function updateMapping(id, name, d2rml, data, parameters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = {}
  if (name && name != null) {
     body = { ...body, name: name }
   }
   if (d2rml && d2rml != null) {
     body = { ...body, d2rml: d2rml }
   }
   if (parameters && parameters != null) {
     body = { ...body, parameters: parameters }
   }
  // return request({
  //   url: API_BASE_URL + "/mappings/update/" + id,
  //   method: 'POST',
  //   body: JSON.stringify(body),
  // })

  var formData = new FormData();
  if (d2rml && d2rml != null && data !== undefined) {
    formData.append('file', data);
  }
  formData.append('body', JSON.stringify(body));

  return jsRequest({
    url: API_BASE_URL + "/mappings/update/" + id,
    method: 'POST',
    body: formData,
  })
}

export function executeMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mappings/execute/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  })
}

export function unpublishMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mappings/unpublish/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST'
  })
}


export function clearMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mappings/clear-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  })
}

export function createMappingParameterBinding(id, parameters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/mappings/createParameterBinding/" + id,
    method: 'POST',
    body: JSON.stringify(parameters)
  })
}

export function deleteMappingParameterBinding(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/mappings/deleteParameterBinding/" + id + "?instanceId=" + instanceId,
    method: 'DELETE'
  });
}

export function downloadMapping(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mappings/download/" + id,
    method: 'GET'
  });

}

export function viewMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/mappings/lastExecution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}


export function downloadMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/mappings/downloadLastExecution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}

export function deleteMapping(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mappings/delete/" + id,
    method: 'DELETE'
  });

}

export function uploadMappingAuxiliaryFile(id, name, file) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var formData = new FormData();
  formData.append('file', file);

  return sRequest({
    url: API_BASE_URL + "/mappings/upload-file/" + id + "",
    method: 'POST',
    body: formData,
  })
}

export function createFile(name, datasetId, data) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var formData = new FormData();
  formData.append('file', data);

  return jsRequest({
    url: API_BASE_URL + "/files/create?name=" + encodeURIComponent(name) + "&datasetId=" + datasetId,
    method: 'POST',
    body: formData,
  })
}

export function updateFile(id, name, data) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = new FormData();
  if (name && name != null) {
    body.append('name', name);
  }
  if (data && data != null) {
    body.append('file', data);
  }

  return jsRequest({
    url: API_BASE_URL + "/files/update/" + id,
    method: 'POST',
    body: body,
  })
}

export function getFiles(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/files/getAll?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function deleteFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/files/delete/" + id,
    method: 'DELETE'
  });

}

export function createDataset(name, type, typeUri, asProperty, links) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/create?name=" + encodeURIComponent(name)
      + "&type=" + type
      + (typeUri ? "&typeUri=" + encodeURIComponent(typeUri) : "")
      + (asProperty ? "&asProperty=" + encodeURIComponent(asProperty) : ""),
    method: 'POST',
    body: JSON.stringify(links)
  })
}

export function createEuropeanaDataset(name, importAs){
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  // let imports = [{name: "API_KEY", value: api_key}]
  // imports.push({name: importMethod.toUpperCase(), value: importText})
  return request({
    url: API_BASE_URL + "/dataset/create/predefined?datasetName=" + name
      + "&importAs=" + importAs,
    method: 'POST',
  });
}

export function updateDataset(id, name, type, typeUri, asProperty, links) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/update/" + id + "?name=" + encodeURIComponent(name)
      + "&type=" + type
      + (typeUri ? "&typeUri=" + encodeURIComponent(typeUri) : "")
      + (asProperty ? "&asProperty=" + encodeURIComponent(asProperty) : ""),
    method: 'POST',
    body: JSON.stringify(links)
  })
}

export function deleteDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/delete/" + id,
    method: 'DELETE',
  })
}

export function getDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/get/" + id,
    method: 'GET',
  })
}

export function getDatasets(type) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/getAll?type=" + type,
    method: 'GET',
  })
}

export function getDatasetDescription(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/dataset/schema/" + id,
    method: 'GET'
  });

}

export function addDataset(id, toId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/insert?id=" + id + "&toId=" + toId,
    method: 'POST',
  })
}

export function removeDataset(id, fromId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/remove?id=" + id + "&fromId=" + fromId,
    method: 'POST',
  })
}

export function publishDataset(id, privat, tripleStore) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/publish/" + id + "?visibility=" + (privat ? "private" : "public") + (tripleStore ? "&triple-store=" + tripleStore : ""),
    method: 'POST',
  })
}

export function publishUnpublishedContent(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/publishUnpublishedContent/" + id,
    method: 'POST',
  })
}

export function republishDatasetMetadata(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/republishMetadata/" + id,
    method: 'POST',
  })
}

export function unpublishDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/unpublish/" + id,
    method: 'POST',
  })
}

export function flipDatasetVisibility(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/flipVisibility/" + id,
    method: 'POST',
  })
}


export function indexDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/index/" + id,
    method: 'POST',
  })
}

export function unindexDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/dataset/unindex/" + id,
    method: 'POST',
  })
}

export function getDatasetSchema(uri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  console.log("YEAHHH")
  return request({
    url: API_BASE_URL + "/data/schema/get?dataset=" + encodeURIComponent(uri),
    method: 'GET',
  })
}

export function getDatasetSchemaNew(uri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/data/schema/get?dataset=" + encodeURIComponent(uri) +"&voc=void",
    method: 'GET',
  })
}

export function getDatasetPropertyValues(uri, path, mode) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/data/schema/getPropertyValues?uri=" + encodeURIComponent(uri) + "&mode=" + mode,
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function createPagedAnnotationValidation(aegId, name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/paged-annotation-validation/create?aegId=" + aegId,
    body: JSON.stringify({ name: name }),
    method: 'POST'
  })
}

export function createFilterAnnotationValidation(aegId, name, filters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/filter-annotation-validation/create?aegId=" + aegId,
    body: JSON.stringify({ name, filters }),
    method: 'POST'
  })
}

export function createFilterTemplate(saveAsName, type, name, filters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + `/template?name=${saveAsName}&type=${type}`,
    body: JSON.stringify({ name, filters }),
    method: 'POST'
  })
}

export function saveApiKey(saveAsName, type, value) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + `/template?name=${saveAsName}&type=${type}&templateString=${value}`,
    method: 'POST'
  })
}

export function getApiKeys() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + `/template?type=API_KEY`,
    method: 'GET'
  })
}


export function updateFilterAnnotationValidation(id, name, filters) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/filter-annotation-validation/update/" + id,
    body: JSON.stringify({ name, filters }),
    method: 'POST'
  })
}


export function executeFilterAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/filter-annotation-validation/execute/" + id,
    method: 'POST',
  })
}

export function executePagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/paged-annotation-validation/execute/" + id,
    method: 'POST',
  })
}

export function viewFilterAnnotationValidationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/filter-annotation-validation/lastExecution/" + id,
    method: 'GET'
  });

}

export function viewPagedAnnotationValidationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/paged-annotation-validation/lastExecution/" + id,
    method: 'GET'
  });

}

export function publishFilterAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/filter-annotation-validation/publish/" + id,
    method: 'POST',
  })
}

export function publishPagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/paged-annotation-validation/publish/" + id,
    method: 'POST',
  })
}

export function unpublishFilterAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/filter-annotation-validation/unpublish/" + id,
    method: 'POST',
  })
}

export function unpublishPagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/paged-annotation-validation/unpublish/" + id,
    method: 'POST',
  })
}

export function editAnnotationValidation(validationId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/editValidation/" + validationId,
    method: 'POST'
  })
}

export function endAnnotationValidation(validationId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/endValidation/" + validationId,
    method: 'POST'
  })
}

export function downloadDatasetPropertyValues(uri, path, mode) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sjRequest({
    url: API_BASE_URL + "/data/schema/downloadPropertyValues?uri=" + encodeURIComponent(uri) + "&mode=" + mode,
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function getOntologyQueryProperties() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/ontology/getQueryProperties",
    method: 'GET',
  })
}

export function getDataAnnotators() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/services",
    method: 'GET',
  })
}

export function getPreprocessFunctions() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/functions",
    method: 'GET',
  })
}

export function getTripleStores() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/dataset/triple-stores",
    method: 'GET',
  })
}


export function createAnnotator(datasetUri, onProperty, asProperty, annotator, thesaurus, parameters, preprocess, variant) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var object = { datasetUri: datasetUri, onProperty: onProperty, asProperty: asProperty, annotator: annotator, variant: variant };

  Object.assign(object, thesaurus && { thesaurus: thesaurus },
    parameters && { parameters: parameters },
    (preprocess && preprocess.length > 0) && { preprocess: preprocess });


  return request({
    url: API_BASE_URL + "/annotator/create",
    body: JSON.stringify(object),
    method: 'POST',
  })
}

export function getTemplate(id){
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + '/template/' + id,
    method: 'GET'
  })
}

export function getEditAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/" + id,
    method: 'GET'
  })
}

export function updateAnnotator(id, asProperty, annotator, thesaurus, parameters, preprocess, variant) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var object = { asProperty: asProperty, annotator: annotator, variant: variant };

  Object.assign(object, thesaurus && { thesaurus: thesaurus },
    parameters && { parameters: parameters },
    (preprocess) && { preprocess: preprocess });

  return request({
    url: API_BASE_URL + "/annotator/" + id,
    body: JSON.stringify(object),
    method: 'PUT'
  })
}

export function createTemplate(name, type, asProperty, annotator, thesaurus, parameters, preprocess, variant) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var object = { asProperty: asProperty, annotator: annotator, variant: variant };
  Object.assign(object, thesaurus && { thesaurus: thesaurus },
    parameters && { parameters: parameters },
    (preprocess && preprocess.length > 0) && { preprocess: preprocess });

  return request({
    url: `${API_BASE_URL}/template?name=${name}&type=${type}`,
    body: JSON.stringify(object),
    method: 'POST',
  })
}

export function deleteTemplate(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/template/" + id,
    method: 'DELETE'
  });

}

export function deleteAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/annotator/delete/" + id,
    method: 'DELETE'
  });

}

export function executeAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/annotator/execute/" + id,
    method: 'POST',
  })
}

export function viewAnnotationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/annotator/lastExecution/" + id,
    method: 'GET'
  });

}

export function downloadAnnotationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/annotator/downloadLastExecution/" + id,
    method: 'GET'
  });

}

export function viewFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/files/view/" + id,
    method: 'GET'
  });

}

export function getAnnotators(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function getAllTemplates(type) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/template?type=" + type,
    method: 'GET',
  })
}

export function getAnnotatorsByUser(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/getAllByUser?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}


export function resolveTime(text) {
  return jRequest({
    url: "https://apps.islab.ntua.gr/intime/api/date?text=" + text,
    method: 'GET',
  })
}

export function resolveSpace(text) {
  return jRequest({
    url: "https://apps.islab.ntua.gr/inplace/api/location?text=" + text,
    method: 'GET',
  })
}

export function resolveThesaurusTerm(text, index, vocabularies) {
  var vocs = [];
  if (vocabularies) {
    for (var i in vocabularies) {
      vocs.push(encodeURIComponent(vocabularies[i]))
    }
  }
  return jRequest({
    url: "https://apps.islab.ntua.gr/inknowledge/api/lookup?text=" + text + "&index=" + index + "&limit=10" + (vocs.length ? "&graphs=" + vocs.join() : ""),
    method: 'GET',
  })
}

export function labelResource(r) {
  return request({
    url: API_BASE_URL + "/f/resources/label?resource=" + encodeURIComponent(r),
    method: 'GET',
  })
}

export function insideTime(resource, vocabularies) {
  vocabularies = vocabularies.map(el => encodeURIComponent(el));
  var voc = "";
  if (vocabularies.length > 0) {
    voc = vocabularies.join(",");
  }

  return jRequest({
    url: "https://apps.islab.ntua.gr/intime/api/inside?resource=" + encodeURIComponent(resource) + "&vocabulary=" + voc,
    method: 'GET'
  })
}

export function publishAnnotators(ids) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/annotator/publish?id=" + ids.join(','),
    method: 'POST',
  })
}

export function unpublishAnnotators(ids) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/annotator/unpublish?id=" + ids.join(','),
    method: 'POST',
  })
}


export function previewAnnotator(id, page) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotator/preview/" + id
      + "?page=" + page,
    method: 'GET',
  })
}


export function addDeleteAnnotationEdit(datasetUri, propertyUri, propertyValue, annotationValue) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotation/addDeleteEdit?datasetUri=" + encodeURIComponent(datasetUri)
      + "&propertyUri=" + encodeURIComponent(propertyUri)
      + "&propertyValue=" + encodeURIComponent(propertyValue)
      + "&annotationValue=" + encodeURIComponent(annotationValue),
    method: 'POST',
  })
}

export function viewValuesAnnotations(id, page, mode, annotators) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var annotatorUuids = annotators.join(",")

  return request({
    url: API_BASE_URL + "/annotation-edit-group/view/" + id
      + "?page=" + page
      + "&mode=" + mode
      + "&annotators=" + annotatorUuids,
    method: 'GET',
  })
}

export function viewAnnotationValidation(id, currentPage, mode, serial, navigation, requestedPage) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  let url = API_BASE_URL + "/paged-annotation-validation/view/" + id + "?currentPage=" + currentPage + "&mode=" + mode;
  if (serial) {
    url = url + "&navigation=" + navigation;
  }
  else {
    url = url + "&requestedPage=" + requestedPage;
  }
  return request({
    url: url,
    method: 'GET'
  })
}

export function downloadAnnotationValues(id, mode) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sjRequest({
    url: API_BASE_URL + "/annotation-edit-group/downloadValues/" + id + "?mode=" + mode,
    method: 'GET',
  })
}


export function getAnnotationEditGroupsByUser(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotation-edit-group/getAllByUser?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function getAnnotationEditGroups(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/annotation-edit-group/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}



export function unpublishAnnotationEdits(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/annotation-edit-group/unpublish/" + id,
    method: 'POST',
  })
}

export function viewAnnotationEditsExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/annotation-edit-group/lastExecution/" + id,
    method: 'GET'
  });

}


export function commitAnnotationEdits(id, edits) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/annotation/commitAnnotationEdits/" + id,
    body: JSON.stringify(edits),
    method: 'POST',
  })
}

export function commitAnnotationValidationPage(id, edits, lockId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/paged-annotation-validation/commit-page/" + id + "?lockId=" + lockId,
    body: JSON.stringify(edits),
    method: 'POST',
  })
}

export function getValidationProgress(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/progress/" + id,
    method: 'GET',
  })
}

export function getDatasetProgress(uuid) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/datasetProgress/" + uuid,
    method: 'GET',
  })
}

export function createVocabularizer(datasetUri, onProperty, name, separator) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }


  return request({
    url: API_BASE_URL + "/vocabularizer/create",
    body: JSON.stringify({ datasetUri, onProperty, name, separator }),
    method: 'POST',
  })
}

export function getVocabulizers(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/vocabularizer/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function deleteVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/vocabularizer/delete/" + id,
    method: 'DELETE'
  });

}

export function executeVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/vocabularizer/execute/" + id,
    method: 'POST'
  });

}

export function viewVocabularizerExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/vocabularizer/lastExecution/" + id,
    method: 'GET'
  });

}

export function publishVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/vocabularizer/publish/" + id,
    method: 'POST',
  })
}

export function unpublishVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/vocabularizer/unpublish/" + id,
    method: 'POST',
  })
}

export function indexVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/vocabularizer/index/" + id,
    method: 'POST',
  })
}

export function unindexVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/vocabularizer/unindex/" + id,
    method: 'POST',
  })
}

export function giveAccessToAllDatasets(validatorId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/access/createAll?validatorId=" + validatorId,
    method: 'POST',
  })
}
export function removeAccessFromAll(validatorId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/access/deleteAll?validatorId=" + validatorId,
    method: 'POST',
  })
}

export function cleanupVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/vocabularizer/cleanup/" + id,
    method: 'GET',
  })
}

export function vocabularyVocabularizer(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/vocabularizer/vocabulary/" + id,
    method: 'GET',
  })
}

export function createDatasetAccess(validatorId, collectionId, uuid) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  if (collectionId) {
    return request({
      url: API_BASE_URL + "/access/createById?validatorId" + validatorId + "&collectionId=" + collectionId,
      method: 'POST',
    });
  }
  else if (uuid) {
    return request({
      url: API_BASE_URL + "/access/createByUuid?validatorId=" + validatorId + "&collectionId=" + uuid,
      method: 'POST',
    });
  }
}

export function removeDatasetAccess(uri, userId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/access/delete?uri=" + uri + "&userId=" + userId,
    method: 'POST',
  });
}

export function removeValidationPageLock(pavId, mode, page) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/locks/remove?pavId=" + pavId + "&mode=" + mode + "&page=" + page,
    method: 'POST',
  });
}

export function index(uri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/index/index?uri=" + encodeURIComponent(uri),
    // body: JSON.stringify(path),
    body: JSON.stringify([]),
    method: 'POST',
  })
}

export function unindex(uri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/index/unindex?uri=" + encodeURIComponent(uri),
    method: 'POST',
  })
}

export function getIndexes(datasetUri) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/index/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}
