import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import { getAllTemplates, createTemplate, deleteTemplate, getTemplate, getEditAnnotator } from "../../../utils/APIUtils"
import '../Profile.css'

import { filterByLanguage, copyReplaceAt } from '../../../utils/functions.js'

export class AnnotatorModal extends Component {
  constructor(props) {
    super(props);
    this.preprocess = [];
    this.state = {
      annotator: null,
      preprocess: [],
      savedAnnotators: [],
      loadedAnnotators: false,
      annotatorTitle: " -- Select an annotator -- ",
      annotatorIdentifier: "",
      showSaveAsForm: false,
      saveAsName: '',
      preprocessSaved: []
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.edit) {
      // API CALL FOR RETRIEVING DATA FROM ANNOTATOR WITH ID this.props.editAnnotatorId Annotator
      this.getEditAnnotatorData(this.props.editAnnotatorId);
    }
  }

  getAnnotatorTitle(id) {
    let annotator = this.props.dataAnnotators.find(a => a.identifier === id);
    if (annotator) {
      return annotator.title;
    }
    return '';
  }

  // Fill all fields except for preprocess. Works for editing and saved annotators
  completeFields(response) {
    this.setState({ annotator: this.props.dataAnnotators.filter(el => el.identifier === response.annotator)[0] }, () => {
      this.property.value = this.state.annotator.asProperties && this.state.annotator.asProperties.length === 1 ? this.state.annotator.asProperties[0] : ''
    })

    this.setState({ annotatorTitle: this.getAnnotatorTitle(response.annotator), annotatorIdentifier: response.annotator});

    if (response.variant != null) {
      this.variant.value = response.variant
    }

    this.property.value = response.asProperty

    if (response.annotator == 'inthesaurus') {
      this.thesaurus.value = response.thesaurus
      this.setState({ thesaurus: this.props.vocabularies.filter(el => el['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === this.thesaurus.value)[0] })
    }

    response.parameters.map(el => {
      this['param-' + el.name].value = el.value
    })
  }

  // Fiil preprocess fields
  completePreprocesses(preproc) {
    for (let i = 0; i < preproc.length; i++) {
      var f = this.props.preprocessFunctions.filter(el => el.uri === preproc[i].function)[0];
      var fp = [];
      for (var j in f.parameters) {
        if (f.parameters[j] !== "input") {
          fp.push({ name: f.parameters[j] })
        }
      }
      this.setState(copyReplaceAt('preprocess', preproc, i, { function: f, parameters: fp }))
      this.preprocess[i].value = preproc[i].function
      this.setState({ preprocess: preproc })
      this.setState({ preprocessSaved: [] })
      for (let i = 0; i < this.state.preprocess.length; i++) {
        this.setState({ preprocessSaved: this.state.preprocessSaved.slice().concat(true) });
      }
    }
  }

  getEditAnnotatorData(id) {
    getEditAnnotator(id).then(response => {
      this.completeFields(response)
      let preproc = response.preprocess != null ? response.preprocess : []
      this.setState({ preprocess: response.preprocess != null ? response.preprocess : [] })
      this.completePreprocesses(preproc)
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
      document.getElementById('submit-btn').innerHTML = 'Update Changes'

    })
  }

  onSavedTemplateClicked(id) {
    getTemplate(id).then(response => {
      this.completeFields(response.templateJson)
      let preproc = response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : []
      this.setState({ preprocess: response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : [] })
      this.completePreprocesses(preproc)
      document.getElementById('modal-title').innerHTML = response.name
    })
  }

  get saveAsCssClasses() {
    return this.state.showSaveAsForm ? 'showForm mt-2 mb-2' : 'hideForm mt-2 mb-2';
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.props.edit) {
      var params = [];
      for (const i in this.state.annotator.parameters) {
        params.push({ name: this.state.annotator.parameters[i].name, value: this['param-' + this.state.annotator.parameters[i].name].value })
      }

      var pp = this.state.preprocess.map((el, index) => {
        return { function: this.state.preprocessSaved[index] ? el.function : el.function.uri, parameters: el.parameters }
      })
      this.props.onUpdate(this.props.editAnnotatorId, this.property.value, this.state.annotatorIdentifier, this.thesaurus ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : null);
    }
    else {
      var params = [];
      for (const i in this.state.annotator.parameters) {
        params.push({ name: this.state.annotator.parameters[i].name, value: this['param-' + this.state.annotator.parameters[i].name].value })
      }

      var pp = this.state.preprocess.map((el, index) => {
        return { function: this.state.preprocessSaved[index] ? el.function : el.function.uri, parameters: el.parameters }
      })
      this.props.onOK(this.property.value, this.state.annotatorIdentifier, this.thesaurus ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : null);
    }
  }

/*
      let preproc = response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : []
      this.setState({ preprocess: response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : [] })
      // console.log(preproc.length, preproc)
      for (let i = 0; i < preproc.length; i++) {
        var f = this.props.preprocessFunctions.filter(el => el.uri === preproc[i].function)[0];
        // console.log(f, i)
        var fp = [];
        for (var j in f.parameters) {
          // console.log(f.parameters)
          if (f.parameters[j] !== "input") {
            fp.push({ name: f.parameters[j] })
          }
        }
        this.setState(copyReplaceAt('preprocess', preproc, i, { function: f, parameters: fp }))
        console.log(preproc)
        this.preprocess[i].value = preproc[i].function
*/

  annotatorChanged(event, annotator) {
    event.preventDefault();

    let annotatorId = annotator.identifier;

    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
    this.setState({ annotator: this.props.dataAnnotators.filter(el => el.identifier === annotatorId)[0] }, () => {
      this.property.value = this.state.annotator.asProperties && this.state.annotator.asProperties.length === 1 ? this.state.annotator.asProperties[0] : ''
      if (this.state.annotator.variants.length > 1 && !this.props.edit) {
        this.variant.value = '';
      }
      this.setState({ preprocess: [], preprocessSaved: [], annotatorTitle: annotator.title, annotatorIdentifier: annotator.identifier });
    });
  }



  functionChanged(index, event) {
    var f = this.props.preprocessFunctions.filter(el => el.uri === event.target.value)[0];
    var fp = [];
    for (var i in f.parameters) {
      if (f.parameters[i] !== "input") {
        fp.push({ name: f.parameters[i] })
      }
    }
    this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f, parameters: fp }))
    let ppSavedtmp = this.state.preprocessSaved;
    ppSavedtmp[index] = false;
    this.setState({ preprocessSaved: ppSavedtmp })
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  thesaurusChanged() {
    this.setState({ thesaurus: this.props.vocabularies.filter(el => el['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === this.thesaurus.value)[0] })
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  addPreprocess() {
    this.setState({ preprocess: this.state.preprocess.slice().concat({ function: '', parameters: [] }) });
    this.setState({ preprocessSaved: this.state.preprocessSaved.slice().concat(false) });
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  deletePreprocess(index) {
    this.setState({ preprocess: this.state.preprocess.slice(0, index).concat(this.state.preprocess.slice(index + 1)) })
    this.setState({ preprocessSaved: this.state.preprocessSaved.slice(0, index).concat(this.state.preprocessSaved.slice(index + 1)) })
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  functionParameterChanged(index, parameter, event) {
    var obj = this.state.preprocess[index];
    var pindex = obj.parameters.findIndex(el => el.name === parameter.name);

    var np = obj.parameters.slice(0, pindex).concat({ name: parameter.name, value: event.target.value }).concat(obj.parameters.slice(pindex + 1));
    this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: obj.function, parameters: np }))
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  parameterChanged(){
    if (this.props.edit) {
      document.getElementById('modal-title').innerHTML = 'Edit Annotator'
    }
    else {
      document.getElementById('modal-title').innerHTML = 'New Annotator'
    }
  }

  jsonldsort(a, b) {
    if (a['@value'] !== undefined && b['@value'] !== undefined) {
      if (a['@value'] < b['@value']) {
        return -1;
      } else if (a['@value'] > b['@value']) {
        return 1;
      } else {
        return 0;
      }
    } else if (a['@id'] !== undefined && b['@id'] !== undefined) {
      if (a['@id'] < b['@id']) {
        return -1;
      } else if (a['@id'] > b['@id']) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  getAllSavedAnnotators() {
    if (this.state.loadedAnnotators) {
      return
    }
    getAllTemplates('ANNOTATOR')
      .then(response => {
        response.sort((a,b) => (a.name > b.name) ? 1 : -1);
        this.setState({ savedAnnotators: response, loadedAnnotators: true });
      });

  }

  nameChanged(evt) {
    this.setState({ saveAsName: evt.target.value })
  }

  onSave() {
    if (!this.state.annotator) {
      this.throwToast('error', 'You have to select an annotator from the list.');
      return;
    }
    if (this.state.saveAsName.length === 0) {
      this.throwToast('error', 'Annotator name cannot be blank. Please enter a name.');
      return;
    }

    var params = [];
    for (const i in this.state.annotator.parameters) {
      params.push({ name: this.state.annotator.parameters[i].name, value: this['param-' + this.state.annotator.parameters[i].name].value })
    }
    var pp = this.state.preprocess.map((el, index) => {
      return { function: this.state.preprocessSaved[index] ? el.function : el.function.uri, parameters: el.parameters }
    })
    createTemplate(this.state.saveAsName, 'ANNOTATOR', this.property.value, this.state.annotatorIdentifier, this.thesaurus ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : null)
      .then(response => {
        this.throwToast('success', 'Annotator saved successfully!')
      })
      .catch(error => {
        console.error(error);
        this.throwToast('error', 'Annotator was not saved. Please try again.')
      });

    document.getElementById('modal-title').innerHTML = this.state.saveAsName
    this.setState({ saveAsName: '', showSaveAsForm: false, loadedAnnotators: false })
  }

  throwToast(type, message) {
    if (type === 'error') {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (type === 'success') {
      toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  onDeleteSavedAnnotator(id) {
    deleteTemplate(id).then(response => {
      this.setState({ loadedAnnotators: false })
      this.getAllSavedAnnotators()
      this.throwToast('success', 'Saved Annotator deleted successfully!')
    })
      .catch(error => {
        console.error(error)
        this.throwToast('error', 'Saved Annotator deletion failed')
      })
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit} id="annotator-modal-form">
          <Modal.Header>
            <Modal.Title id="modal-title">New Annotator</Modal.Title>
            <Row className="mr-0">
              <DropdownButton title="Load Template" variant="outline-primary" onClick={() => this.getAllSavedAnnotators()}>
                <div className="dropdown-scroll-annotator">
                  {this.state.savedAnnotators.map((el, index) => (
                    <Dropdown.Item key={"annotator-" + index}>
                      <Row>
                        <Col className="col-10" onClick={() => this.onSavedTemplateClicked(el.id)}>
                          {el.name}
                        </Col>
                        <Col>
                          <i className="fa fa-trash float-right red" aria-hidden="true" onClick={() => this.onDeleteSavedAnnotator(el.id)}></i>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </Row>
          </Modal.Header>

          <Modal.Body>
            <Form.Group id="annotator-selector">
              <Form.Label className="bold">Annotator</Form.Label>
              <DropdownButton
                id="annotator-selector"
                title={this.state.annotatorTitle}
                variant="light"
                bsPrefix="form-control w-100"
              >
                {this.props.dataAnnotators.map((el, index) =>
                  <OverlayTrigger key={`overlay-${index}`} placement="top" delay={150} overlay={<Tooltip className="annotator-tooltip" id={`tooltip-${index}`}>{el.description !== null ? el.description : el.title}</Tooltip>}>
                    <Dropdown.Item as="button" className="py-1" key={`annotator-${index}`} onClick={e => this.annotatorChanged(e, el)}>
                      {el.title}
                    </Dropdown.Item>
                  </OverlayTrigger>
                )}
              </DropdownButton>
            </Form.Group>

            {this.state.annotator && this.state.annotator.variants.length > 1 &&
              <Form.Group>
                <Form.Label>Variant</Form.Label>
                <Form.Control as="select" ref={node => (this.variant = node)}
                  defaultValue=''
                  required>
                  <option hidden disabled value=''> -- Select a variant -- </option>
                  {this.state.annotator && this.state.annotator.variants.map((el, index) =>
                    <option key={index} value={el.name}>{el.name}</option>
                  )}
                </Form.Control>
              </Form.Group>}

            <Form.Group>
              <Form.Label>View as</Form.Label>
              <Form.Control as="select" ref={node => (this.property = node)}
                defaultValue=''
                required>
                <option hidden disabled value=''> -- Select a property -- </option>
                {this.state.annotator && this.state.annotator.asProperties && this.state.annotator.asProperties.map((el, index) =>
                  <option key={index} value={el}>{el}</option>
                )}
              </Form.Control>
            </Form.Group>
            {this.state.annotator && this.state.annotator.identifier === 'inthesaurus' &&
              <Form.Group>
                <Form.Label>Thesaurus</Form.Label>
                <Form.Control as="select" ref={node => (this.thesaurus = node)}
                  onChange={() => this.thesaurusChanged()}
                  defaultValue=''
                  required>
                  <option hidden disabled value=''> -- Select a thesaurus -- </option>
                  {this.props.vocabularies.map((el, index) =>
                    <option key={index} value={el['http://purl.org/dc/elements/1.1/identifier'][0]['@id']}>{filterByLanguage(el, 'http://www.w3.org/2000/01/rdf-schema#label', 'el')}</option>)}
                </Form.Control>
              </Form.Group>}
            {this.state.annotator && this.state.annotator.parameters && this.state.annotator.parameters.map((el, index) =>
              <Form.Group key={index}>
                <Form.Label>{el.name}</Form.Label>
                {el.values.length === 0 && (el.type === "text" ?
                  <Form.Control as="textarea" ref={node => (this['param-' + el.name] = node)} /> :
                  <Form.Control ref={node => (this['param-' + el.name] = node)} />)
                }
                {el.values.length > 0 &&
                  <Form.Control as="select" ref={node => (this['param-' + el.name] = node)}
                    onChange={() => this.parameterChanged()}
                    defaultValue={el.defaultValue}
                    >
                    {/* <option hidden disabled value=''> -- Select a value -- </option> */}
                    {el.values.map((v, index) => {
                      var matches = v.match("^\\{(.*)\\}$")
                      if (matches) {
                        if (this.state.thesaurus && this.state.thesaurus[matches[1]]) {
                          return this.state.thesaurus[matches[1]].sort(this.jsonldsort).map((t, id) => { return <option key={index + "_" + id} value={t['@value'] ? t['@value'] : t['@id']}>{t['@value'] ? t['@value'] : t['@id']}</option> })
                        } else {
                          return '';
                        }
                      } else {
                        return <option key={index} value={v}>{v}</option>
                      }
                    })}
                  </Form.Control>}
              </Form.Group>
            )}
            <Row className="tableheader mb-3" />

            <Form.Group className="mb-0">
              <Form.Label className="bold">Preprocess</Form.Label>
              <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addPreprocess(event)}><span className="fa fa-plus"></span></Button>
            </Form.Group>

            {this.state.preprocess.map((el, index) =>
              <Row key={index}>
                <Col>
                  <Form.Group>
                    <Form.Label>Function</Form.Label>
                    <Form.Control as="select"
                      ref={node => (this.preprocess[index] = node)}
                      onChange={(event) => this.functionChanged(index, event)}
                      defaultValue=''>
                      <option hidden disabled value=''> -- Select a function -- </option>
                      {this.props.preprocessFunctions.map((el2, index2) =>
                        <option key={index2}>{el2.uri}</option>
                        // el2.uri === this.state.preprocess[index].function.uri ?
                        //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  {el.parameters && el.parameters.filter(el2 => el2 !== "input").map((el2, index2) =>
                    <Form.Group key={index2}>
                      <Form.Label>{el2.name}</Form.Label>
                      <Form.Control onChange={(v) => this.functionParameterChanged(index, el2, v)}
                        defaultValue={el2.value}
                        required>
                      </Form.Control>
                    </Form.Group>)}
                </Col>
                <Col md="auto">
                  <Button type="button" className="deleteeditbutton" aria-label="New" onClick={(event) => this.deletePreprocess(index)}><span className="fa fa-times"></span></Button>
                </Col>

              </Row>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            {this.state.showSaveAsForm ?
              <InputGroup className={this.saveAsCssClasses}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    Save as:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  onChange={(event) => this.nameChanged(event)}
                  defaultValue={this.state.saveAsName}>
                </FormControl>
                <Button variant="outline-danger" className="ml-3" onClick={() => this.setState({ showSaveAsForm: false, saveAsName: '' })}>Cancel</Button>
                <Button variant="primary" className="ml-3" onClick={() => this.onSave()}>Save</Button>
              </InputGroup> :

              <React.Fragment>
                <Button onClick={() => this.setState(prevState => ({ showSaveAsForm: !prevState.showSaveAsForm }))} variant="outline-primary" className="float-left">
                  Save Template
                </Button>
                <div>
                  <Button variant="outline-danger" className="mr-2" onClick={this.props.onClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" id="submit-btn">
                    Create Annotator
                  </Button>
                </div>
              </React.Fragment>}
          </Modal.Footer>
        </Form>
        {/* <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </Modal>

    )
  }
}

export default AnnotatorModal;
