import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactTooltip from "react-tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { executeMapping, updateMapping, downloadMapping, createMapping, deleteMapping, uploadMappingAuxiliaryFile, createFile, viewMappingExecution, downloadMappingExecution, clearMappingExecution, createMappingParameterBinding, deleteMappingParameterBinding } from '../../utils/APIUtils';
import { getMappings, getFiles, deleteFile, viewFile, updateFile } from '../../utils/APIUtils';
import { convert } from '../../utils/D2RMLUtils';
import { addDataset, removeDataset } from '../../utils/APIUtils';

import D2RMLEditor from "./d2rml/D2RMLEditor.js";
import NewMappingUploadModal from "./modals/NewMappingUploadModal.js";
import NewDataUploadModal from "./modals/NewDataUploadModal.js";
import NewBindingModal from "./modals/NewBindingModal.js";
import PublishModal from "./modals/PublishModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import IndexableState from "./IndexableState.js";
import ResultsModal from "./modals/ResultsModal.js";
import DeleteModal from "./modals/DeleteModal.js";
import NewDatasetLinkModal from "./modals/NewDatasetLinkModal.js";

export class ModularDatasetEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {

      mappings: [],
      currentMappingIndex: null,

      files: [],
      currentFileIndex: null,

      newMappingModalShow: false,
      newMappingModalMapping: null,

      newBindingModalShow: false,
      parameters: [],
      mappingId: null,

      newDataModalShow: false,

      newDatasetLinkModalShow: false,
      publishModalShow: false,
      publishFlag: true,

      resultsShow: false,
      resultsState: { loaded: false, loading: false, failed: false },

    };

    this.datasetUpdated(props);
  }
  componentDidMount() {
    this.publishDisabled()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillReceiveProps(props) {
    // console.log(props.dataset)
    if (this.props.dataset.id !== props.dataset.id) {

      this.currentMappingIndex = null;
      this.datasetUpdated(props)
    }
    this.publishDisabled()
    // console.log(this.state.publishFlag)
    // this.setState(this.createMapping(props));

  }

  addDataset(id, toId) {
    addDataset(id, toId)
      .then(() => {
        this.props.actions('add-dataset-to-catalog', { id: id })
      })
  }

  removeDataset(id, fromId) {
    removeDataset(id, fromId)
      .then(() => {
        this.props.actions('remove-dataset-from-catalog', { id: id })
      })
  }

  mappingsSort(a, b) {
    if (a.type === "PREFIX" && (b.type === "HEADER" || b.type === "CONTENT")) {
      return -1;
    } else if (a.type === "HEADER" && b.type === "CONTENT") {
      return -1;
    } else if (a.type === "HEADER" && b.type === "PREFIX") {
      return 1;
    } else if (a.type === "CONTENT" && (b.type === "PREFIX" || b.type === "HEADER")) {
      return 1;
    } else {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  fielsSort(a, b) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  }

  datasetUpdated(props) {
    if (props.dataset.id) {
      const _this = this;
      getMappings(props.dataset.id)
        .then(response => {
          response.sort(this.mappingsSort);
          _this.setState({ mappings: response, currentMappingIndex: null });
          this.publishDisabled()
        })

      getFiles(props.dataset.id)
        .then(response => {
          _this.setState({ files: response, currentFileIndex: null });
        })

    }
  }

  handleServerEvent(message) {
    // console.log(message);
    if (message.state.startsWith("PUBLISHED") || message.state.startsWith("UNPUBLISHED")){
      this.datasetUpdated(this.props)    
    }

      if (message.state === "EXECUTING") {
      if (message.startedAt) {
        // console.log(message.startedAt);
        if (message.instanceId) {
          this.setState({
            mappings: this.state.mappings.map(el =>
              el.id !== message.id ?
                el : {
                  ...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                    { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, maps: message.maps.map(m => { return { ...m, completed: false } }) } : inst)
                }
            )
          });
        } else {
          this.setState({
            mappings: this.state.mappings.map(el =>
              el.id !== message.id ?
                el : { ...el, instances: el.instances.map(inst => { return { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, maps: message.maps.map(m => { return { ...m, completed: false } }) } }) }
            )
          })
        }
      } else if (message.executionInfo) {
        if (message.executionInfo) {
          if (message.instanceId) {
            this.setState({
              mappings: this.state.mappings.map(el =>
                el.id !== message.id ?
                  el : {
                    ...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                      {
                        ...inst, [message.type + 'State']: message.state,
                        maps: inst.maps ?
                          (inst.maps.some(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource) ?
                            inst.maps.map(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource ?
                              message.executionInfo : map) : [...inst.maps, message.executionInfo])
                          : [message.executionInfo]
                      } : inst)
                  }
              )
            });
          } else {
            this.setState({
              mappings: this.state.mappings.map(el =>
                el.id !== message.id ?
                  el : {
                    ...el, instances: el.instances.map(inst => {
                      return {
                        ...inst, [message.type + 'State']: message.state, [message.type + 'CompletedAt']: message.completedAt,
                        maps: inst.maps ?
                          (inst.maps.some(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource) ?
                            inst.maps.map(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource ?
                              message.executionInfo : map) : [...inst.maps, message.executionInfo])
                          : [message.executionInfo]
                      }
                    })
                  }
              )
            })
          }
        }
      }
    } else if (message.state === "EXECUTED" || message.state === "EXECUTION_FAILED") {
      if (message.instanceId) {
        this.setState({
          mappings: this.state.mappings.map(el =>
            el.id !== message.id ?
              el : {
                ...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                  { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt, message: message.message, maps: inst.maps.map(m => { return { ...m, completed: true } }) } : inst)
              }
          )
        });
        this.publishDisabled();
      } else {
        this.setState({
          mappings: this.state.mappings.map(el =>
            el.id !== message.id ?
              el : { ...el, instances: el.instances.map(inst => { return { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt, message: message.message } }) }
          )
        })
      }
    }
  }


  executeAction(action, params) {
    if (action === 'create-mapping') {
      if (params.id && params.id != null) {
        updateMapping(params.id, params.name, params.json, params.data, params.parameters)
          .then(response => {
            this.setState({ mappings: this.state.mappings.map(el => el.id === params.id ? { ...el, name: params.name, d2RML: params.json ? params.json : el.d2RML, parameters: params.parameters } : el).sort(this.mappingsSort) });
            this.setState({ newMappingModalShow: false, newMappingModalMapping: null });
          })
      } else {
        createMapping(params.datasetId, this.state.newMappingType, params.name, params.json, params.data, params.parameters)
          .then(response => {
            this.setState({ mappings: this.state.mappings.slice().concat(response).sort(this.mappingsSort) })
            this.setState({ newMappingModalShow: false, newMappingModalMapping: null });
          })
      }
    } else if (action === 'create-file') {
      if (params.id && params.id != null) {
        updateFile(params.id, params.name, params.data)
          .then(response => {
            // console.log(response);
            this.setState({ files: this.state.files.map(el => el.id === params.id ? { ...el, name: params.name, fileName: response.fileName } : el).sort(this.filesSort) });
          })
      } else {
        createFile(params.name, params.datasetId, params.data)
          .then(response => {
            // console.log(response);
            this.setState({ files: this.state.files.slice().concat(response).sort(this.filesSort) })
          })
      }
      this.fileModal.loadingCompleted();
    } else if (action === 'delete-file') {
      this.setState({ deleteModalShow: true, deleteModalCommand: 'delete-file', deleteModalParams: params })
    } else if (action === 'delete-file-ok') {
      deleteFile(params.id)
        .then(response => {
          this.setState({ files: this.state.files.filter(el => el.id !== params.id) })
        })
    } else if (action === 'save-mapping') {
      // console.log('SAVING');
      // console.log(params.id);
      // console.log(params.json);
      // console.log(this.state.mappings[this.state.currentMappingIndex].id);
      // console.log(value);
      // console.log(files);

      var filePromises = [];
      const _this = this;
      for (const i in params.files) {
        if (params.files[i].blob !== undefined) {
          filePromises.push(
            new Promise(function (resolve, reject) {
              uploadMappingAuxiliaryFile(_this.state.mappings[_this.state.currentMappingIndex].id, params.files[i].name, params.files[i].blob)
                .then(resolve())
            }
            ))
        }
      }

      Promise.all(filePromises).then(() => {
        updateMapping(params.id, null, params.json)
          .then(response => {
            this.setState({ mappings: this.state.mappings.map(el => el.id === params.id ? { ...el, d2RML: params.json ? params.json : el.d2RML } : el) });
            // this.setState({ newMappingModalShow: false, newMappingModalMapping: null });
          })
        // updateMapping(this.state.mappings[this.state.currentMappingIndex].id, this.state.mappings[this.state.currentMappingIndex].id.name, JSON.stringify(params.d2rml))
        // .then(() =>  {
        //    this.setState(copyReplaceAt('D2RMLMappings', this.state.mappings, this.state.currentIndex, { ...this.state.mappings[this.state.currentIndex], d2RML: JSON.stringify(value)}))
        // })
      })


    } else if (action === 'execute-mapping') {
      // console.log(this.state.mappings);
      executeMapping(params.id, params.instanceId)
        .then(() => {
          // if (params.instanceId) {
          //   this.setState({ mappings: this.state.mappings.map(el =>
          //       el.id !== params.id ?
          //          el : {...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
          //            { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null} : inst)}
          //   )})
          // } else {
          //
          //   this.setState({ mappings: this.state.mappings.map(el =>
          //       el.id !== params.id ?
          //          el : {...el, instances: el.instances.map(inst => {return { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null}})}
          //   )})
          // }
        }
        )
    } else if (action === 'clear-mapping-execution') {
      // console.log(this.state.mappings);
      clearMappingExecution(params.id, params.instanceId)
        .then(() => {
          if (params.instanceId) {
            this.setState({
              mappings: this.state.mappings.map(el =>
                el.id !== params.id ?
                  el : {
                    ...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
                      { ...inst, executeState: null, executeStartedAt: null, executeCompletedAt: null, maps: null } : inst)
                  }
              )
            });
            this.publishDisabled();
          } else {
            this.setState({
              mappings: this.state.mappings.map(el =>
                el.id !== params.id ?
                  el : { ...el, instances: el.instances.map(inst => { return { ...inst, executeState: null, executeStartedAt: null, executeCompletedAt: null, maps: null } }) }
              )
            })
            this.publishDisabled();
          }
          // if (params.instanceId) {
          //   this.setState({ mappings: this.state.mappings.map(el =>
          //       el.id !== params.id ?
          //          el : {...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
          //            { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null} : inst)}
          //   )})
          // } else {
          //
          //   this.setState({ mappings: this.state.mappings.map(el =>
          //       el.id !== params.id ?
          //          el : {...el, instances: el.instances.map(inst => {return { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null}})}
          //   )})
          // }
        }
        )
    } else if (action === 'download-d2rml') {
      downloadMapping(params.id)
      // } else if (action === 'update-name') {
      // console.log(this.state.currentIndex);
      // console.log(value);
      // var currentMapping = {...this.state.mappings[this.state.currentMappingIndex], name:value };
      //
      // this.setState(copyReplaceAt('D2RMLMappings', this.state.mappings, this.state.currentIndex, currentMapping));
    } else if (action === 'delete-mapping') {
      this.setState({ deleteModalShow: true, deleteModalCommand: 'delete-mapping', deleteModalParams: params })
    } else if (action === 'delete-mapping-ok') {
      deleteMapping(params.id)
        .then(() =>
          // this.setState({ mappings: splice(this.state.mappings, this.state.currentMappingIndex), currentMappingIndex: null}))
          this.setState({ mappings: this.state.mappings.filter(el => el.id !== params.id), currentMappingIndex: null }))
    } else if (action === 'view-mapping-execution') {
      this.setState({ lastExecution: null, resultsShow: true, resultsState: { loaded: false, loading: true, failed: false } }, () =>
        viewMappingExecution(params.id, params.instanceId)
          .then(response => {
            response.text().then(text =>
              this.setState({ lastExecution: { text: text }, resultsState: { loaded: true, loading: false, failed: false } }))
          },
            () => this.setState({ lastExecution: null, resultsState: { loaded: false, loading: false, failed: true } }))
      )
    } else if (action === 'download-mapping-execution') {
      downloadMappingExecution(params.id, params.instanceId)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = filename;
              a.click();
            });
          } else {
            Promise.reject(response)
          }
        },
          () => Promise.reject())
    } else if (action === 'view-prefix-mapping') {
      // this.setState({ lastExecution: null, resultsShow:true, resultsState: { loaded: false, loading: true, failed: false}}, () =>
      //   viewPrefixMapping(params.id)
      //     .then(response => {
      //       response.text().then(text =>
      //         this.setState({ lastExecution: { text: text }, resultsState: {loaded: true, loading: false, failed: false} }))},
      //         () => this.setState({ lastExecution: null, resultsState: {loaded: false, loading: false, failed: true}}) )
      //   )
    } else if (action === 'view-file') {
      this.setState({ lastExecution: null, resultsShow: true, resultsState: { loaded: false, loading: true, failed: false } }, () =>
        viewFile(params.id)
          .then(response => {
            response.text().then(text =>
              this.setState({ lastExecution: { text: text }, resultsState: { loaded: true, loading: false, failed: false } }))
          },
            () => this.setState({ lastExecution: null, resultsState: { loaded: false, loading: false, failed: true } }))
      )
    } else if (action === 'add-parameter-binding') {
      this.setState({ parameters: this.state.mappings[params.index].parameters, mappingId: params.id, newBindingModalShow: true })
    } else if (action === 'create-parameter-binding') {
      createMappingParameterBinding(params.id, params.parameters)
        .then((json) =>
          this.setState({
            mappings: this.state.mappings.map(el =>
              el.id !== params.id ?
                el : { ...el, instances: el.instances.concat(json) }),
            newBindingModalShow: false
          })
        )
    } else if (action === 'delete-mapping-binding') {
      this.setState({ deleteModalShow: true, deleteModalCommand: 'delete-mapping-binding', deleteModalParams: params })
    } else if (action === 'delete-mapping-binding-ok') {
      deleteMappingParameterBinding(params.id, params.instanceId)
        .then(() => this.setState({
          mappings: this.state.mappings.map(el =>
            el.id !== params.id ?
              el : { ...el, instances: el.instances.filter(inst => inst.id !== params.instanceId) })
        })
        )
    } else if (action === 'update-mapping') {
      this.setState({ newMappingModalShow: true, newMappingType: params.mapping.type, newMappingModalMapping: params.mapping })
    } else if (action === 'update-file') {
      this.setState({ newDataModalShow: true, newDataModalFile: params.file })
    }
  }

  selectMapping(index) {
    this.setState({ currentMappingIndex: index })
  }

  isPrefixMapping(el) {
    return el.type === 'PREFIX';
  }

  publishDisabled() {
    let contentMappings = this.state.mappings.filter(mapping => mapping.type === "CONTENT")
    let executedFound = false;
    contentMappings.forEach((mapping) => {
      for (let inst of mapping.instances) {
        if (inst.executeState === "EXECUTED") {
          this.setState({ publishFlag: true })
          executedFound = true
          break;
        }
      }
    })
    if (!executedFound) {
      this.setState({ publishFlag: false })
    }
  }

  render() {
    var currentMapping = null;
    if (this.state.currentMappingIndex !== null) {
      currentMapping = this.state.mappings[this.state.currentMappingIndex]
      console.log("SSE")
    }
    var published = this.props.dataset.publishState === "PUBLISHED_PUBLIC" || this.props.dataset.publishState === "PUBLISHED_PRIVATE";
    var indexed = this.props.dataset.indexState === "INDEXED";

    return (
      <Container>
        <Row>
          <Col>
            <Container className="groupborder">
              <Row className="header">
                <Col className="bold">
                  {this.props.dataset.name}
                </Col>
                <Col className="mybutton" md="auto">
                  {this.state.newCatalogLinkModalShow &&
                    <NewDatasetLinkModal
                      show={this.state.newCatalogLinkModalShow}
                      datasets={this.props.datasets}
                      onOK={(id) => { this.addDataset(id, this.props.dataset.id); this.setState({ newCatalogLinkModalShow: false }) }}
                      onClose={() => this.setState({ newCatalogLinkModalShow: false })}
                    />
                  }
                  <DropdownButton size="sm" drop="down" title="Dataset actions" className="actions">
                    <Dropdown.Item className="py-2" onClick={() => this.props.actions('update-dataset', { id: this.props.dataset.id, type: this.props.type, scope: this.props.scope })}>
                      <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit dataset
                    </Dropdown.Item>
                    <div data-tip={!(!published && !indexed && (this.props.type === 'dataset' || (this.props.type === 'catalog' && this.props.dataset.datasets.length === 0)))
                      ? "You cannot delete a published dataset" : null}
                      data-place="left"
                      data-effect="solid">
                      <Dropdown.Item
                        className="py-2"
                        disabled={!(!published && !indexed && (this.props.type === 'dataset' || (this.props.type === 'catalog' && this.props.dataset.datasets.length === 0)))}
                        onClick={() => this.props.actions('delete-dataset', { id: this.props.dataset.id, type: this.props.type })}
                      >
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete dataset
                      </Dropdown.Item>
                    </div>
                    <ReactTooltip delayShow={200} />

                    <Dropdown.Divider />
                    {!published &&

                      <div data-tip={!this.state.publishFlag ? "There is nothing executed to publish" : null}
                        data-place="left"
                        data-effect="solid">
                        <Dropdown.Item className="py-2" disabled={!this.state.publishFlag} onClick={(event) => this.setState({ publishModalShow: true })}>
                          <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3" />Publish
                        </Dropdown.Item>
                      </div>
                    }
                    {published &&
                      <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('publish-unpublished-content', { id: this.props.dataset.id })}>
                        <span className="menu-icon fa fa-calendar-minus-o fa-lg mr-3" />Publish Unpublished Content
                      </Dropdown.Item>
                    }
                    {(true || this.props.metadata) && (published || !published) &&
                      <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('republish-dataset-metadata', { id: this.props.dataset.id })}>
                        <span className="menu-icon fa fa-calendar fa-lg mr-3" />Republish Metadata
                      </Dropdown.Item>
                    }
                    <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('unpublish-dataset', { id: this.props.dataset.id, type: this.props.type })}>
                      <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3" />Unpublish
                    </Dropdown.Item>
                    {this.props.metadata && published &&
                      <Dropdown.Item className="py-2" onClick={(event) => this.props.actions('flip-dataset-visibility', { id: this.props.dataset.id })}>
                        <span className="menu-icon fa fa-exchange fa-lg mr-3" />Flip Visibility
                      </Dropdown.Item>
                    }
                    <Dropdown.Divider />
                    {this.props.type === 'dataset' &&
                      <div data-tip={(!published || indexed) ? "Only published datasets can be indexed" : null}
                        data-place="left"
                        data-effect="solid">
                        <Dropdown.Item className="py-2" disabled={!published || indexed} onClick={(event) => this.props.actions('index-dataset', { id: this.props.dataset.id })}>
                          <span className="menu-icon fa fa-check-circle-o fa-lg mr-3" />Index
                        </Dropdown.Item>
                      </div>
                    }
                    {this.props.type === 'dataset' &&
                      <Dropdown.Item className="py-2" disabled={!indexed} onClick={(event) => this.props.actions('unindex-dataset', { id: this.props.dataset.id })}>
                        <span className="menu-icon fa fa-times-circle-o fa-lg mr-3" />Unindex
                      </Dropdown.Item>
                    }
                  </DropdownButton>
                </Col>
              </Row>
              <Row className="tableheader pt-1 pb-3">
                <Col>
                  <Row>
                    <Col>
                      <span>Dataset ID</span>
                    </Col>
                    <Col md="auto" className="datasetId">
                      {this.props.dataset.uuid}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>Publish state</span>
                    </Col>
                    <Col md="auto">
                      <PublishableState value={this.props.dataset} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>Index state</span>
                    </Col>
                    <Col md="auto">
                      <IndexableState value={this.props.dataset} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {this.state.mappings.map((el, index) =>
                el.type !== 'CONTENT' ?
                  <React.Fragment key={index} />
                  :
                  <Row key={index}>
                    <Col className="mappingRow pt-1 pb-3 px-4">
                      <Row className="text-center">
                        <Col>
                          <span className="abutton alink text-uppercase font-weight-bold" onClick={() => this.selectMapping(index)}>
                            {el.name}
                          </span>
                        </Col>
                      </Row>
                      {el.instances.map((inst, index) =>
                        <Row key={index} className="mappingInstanceRow">
                          <Col md="7">
                            {inst.binding.map((binding, bindex) =>
                              <Row key={bindex} className="border">
                                <Col md="auto p-1">
                                  {binding.name}:
                                  <p className="red mb-0">{binding.value}</p>
                                </Col>
                              </Row>
                            )}
                          </Col>
                          <Col md="3" className="tablecenter align-self-center px-3">
                            <div>
                              <Row>
                                <Col>
                                  <ExecutableState value={inst} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <PublishableState value={inst} />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md="2" className="align-self-center">
                            <DropdownButton size="sm" title="Actions" className="actions">
                              <Dropdown.Item className="py-2" onClick={() => this.executeAction('delete-mapping-binding', { id: el.id, instanceId: inst.id, index: index })}>
                                <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete instance
                              </Dropdown.Item>
                              <Dropdown.Item className="py-2" onClick={() => this.executeAction('execute-mapping', { id: el.id, instanceId: inst.id })}>
                                <span className="menu-icon fa fa-play-circle-o fa-lg mr-3" />Execute instance
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item disabled={inst.executeState !== 'EXECUTED'} className="py-2" onClick={() => this.executeAction('view-mapping-execution', { id: el.id, instanceId: inst.id })}>
                                <span className="menu-icon fa fa-eye fa-lg mr-3" />View last execution
                              </Dropdown.Item>
                              <Dropdown.Item disabled={inst.executeState !== 'EXECUTED'} className="py-2" onClick={() => this.executeAction('download-mapping-execution', { id: el.id, instanceId: inst.id })}>
                                <span className="menu-icon fa fa-download fa-lg mr-3" />Download last execution
                              </Dropdown.Item>
                              <Dropdown.Item disabled={inst.executeState !== 'EXECUTED'} className="py-2" onClick={() => this.executeAction('clear-mapping-execution', { id: el.id, instanceId: inst.id })}>
                                <span className="menu-icon fa fa-times fa-lg mr-3" />Clear execution
                              </Dropdown.Item>
                            </DropdownButton>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="d-inline-flex justify-content-center">
                          <Button className="mt-2 btn-sm" variant="outline-secondary" onClick={() => this.executeAction('add-parameter-binding', { id: el.id, index: index })}>
                            <span className="fa fa-plus fa-lg mr-2" />Add {el.name}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
              )}

              {this.state.files.length > 0 &&
                <React.Fragment>
                  <Row className="tableheader" />
                  <Row className="tableheader">
                    <Col md={3}>
                      <span>File</span>
                    </Col>
                    <Col md={3}>
                      <span>Filename</span>
                    </Col>
                    <Col md={5} className="tablecenter">
                      <span>State</span>
                    </Col>
                    <Col md={2} />
                  </Row>
                </React.Fragment>
              }

              {this.state.files.map((el, index) =>
                <Row key={index}>
                  <Col>
                    <span className="abutton">{el.name}</span>
                  </Col>
                  <Col className="mybutton" md={1}>
                    <DropdownButton size="sm" title="" className="actions">
                      <Dropdown.Item onClick={() => this.executeAction('update-file', { file: el })}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.executeAction('delete-file', { id: el.id })}>Delete</Dropdown.Item >
                    </DropdownButton>
                  </Col>
                  <Col md={3} className="tablecenter">
                    {el.fileName ? el.fileName : ''}
                  </Col>
                  <Col md={5} className="tablecenter">
                    <div>
                      <Row>
                        <Col>
                          <PublishableState value={el} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={1}>
                    <Row>
                      <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="View contents" onClick={() => this.executeAction('view-file', { id: el.id })}><span className="fa fa-eye"></span></Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>

            {currentMapping &&
              <Container className="groupborder">
                <D2RMLEditor
                  value={currentMapping}
                  fixedSubject={currentMapping.type === 'HEADER' ? true : false}
                  delete={currentMapping.parameters.length === 0 || currentMapping.instances.length === 0}
                  actions={(action, value, files) => this.executeAction(action, value, files)}
                />
              </Container>
            }
          </Col>
        </Row>

        <NewBindingModal
          show={this.state.newBindingModalShow} mapping={this.state.mappingId} parameters={this.state.parameters}
          onOK={(id, params) => this.executeAction('create-parameter-binding', { id: id, parameters: params })}
          onClose={() => this.setState({ newBindingModalShow: false })}
        />
        <DeleteModal
          show={this.state.deleteModalShow}
          command={this.state.deleteModalCommand}
          params={this.state.deleteModalParams}
          actions={(choice, command, params) => { this.setState({ deleteModalShow: false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params) : null) }}
        />
        <ResultsModal
          show={this.state.resultsShow}
          state={this.state.resultsState}
          execution={this.state.lastExecution}
          onClose={() => this.setState({ resultsShow: false })}
        />
        <NewMappingUploadModal
          show={this.state.newMappingModalShow}
          type={this.state.newMappingType}
          mapping={this.state.newMappingModalMapping}
          uuid={this.props.dataset.uuid}
          onOK={(id, name, json, data, parameters) => this.executeAction('create-mapping', { datasetId: this.props.dataset.id, id: id, name: name, json: json, data: data, parameters: parameters })}
          onClose={() => this.setState({ newMappingModalShow: false, newMappingType: undefined })}
        />
        <NewDataUploadModal
          show={this.state.newDataModalShow}
          uuid={this.props.dataset.uuid}
          file={this.state.newDataModalFile}
          ref={node => (this.fileModal = node)}
          onOK={(id, name, data) => this.executeAction('create-file', { datasetId: this.props.dataset.id, id: id, name: name, data: data })}
          onClose={() => this.setState({ newDataModalShow: false })}
        />
        <PublishModal
          show={this.state.publishModalShow} value={this.props.dataset} allowPublic={this.props.user.type === "EDITOR"}
          visibility={this.props.visibility}
          onOK={(privat) => { this.props.actions('publish-dataset', { id: this.props.dataset.id, visibility: privat, type: this.props.type }); this.setState({ publishModalShow: false }) }}
          onClose={() => this.setState({ publishModalShow: false })}
        />
      </Container >
    );
  }
}

export default ModularDatasetEditor;
