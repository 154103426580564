import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from 'react-toastify';
import TableScrollbar from 'react-table-scrollbar';

import { Localizer } from '../../config/localizer.js'
import LoadingComponent from '../LoadingComponent';
import { processDatasets } from "../Datasets.js";
import { filterByLanguage } from "../../utils/functions.js";
import { getAccessedDatasets } from '../../utils/DatasetUtils.js';
import { getValidatorsOfEditor, getEditorsOfValidator, getPublicEditors, joinEditor, removeValidator, createDatasetAccess, removeDatasetAccess, getDatasetProgress, giveAccessToAllDatasets, removeAccessFromAll } from '../../utils/APIUtils.js';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      selectionTimestamp: 0,
      publicEditors: [],
      myEditors: [],
      myValidators: [],
      ownedDatasets: null,
      assignedDatasets: null,
      nonAssignedDatasets: null,
      datasetsProgress: [],
      allDatasetsAssigned: false,
      allDatasetsNonAssigned: false,
      everythingLoaded: false,
      assignedDatasetslength: 0
    };
  }

  getPublicEditors() {
    getPublicEditors()
      .then(response => {
        if (response.editors) {
          this.setState({ publicEditors: response.editors });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getEditors() {
    getEditorsOfValidator()
      .then(response => {
        if (response.editors) {
          this.setState({ myEditors: response.editors });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getValidators() {
    getValidatorsOfEditor()
      .then(response => {
        if (response.users) {
          this.setState({ myValidators: response.users });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getAssignedDatasets(userId) {
    getAccessedDatasets(userId)
      .then(response => {
        this.state.assignedDatasetslength = response.length;
        var datasets = response.map(el => {
          return { dataset: el, selected: false };
        });
        if (this.props.mode === 'validator' && this.state.datasetsProgress.length === 0) {
          this.getDatasetsProgress(datasets);
        }
        let ownedAssignedDifference = [];
        for (let entry of this.props.datasets) {
          if (!datasets.map(x => x.dataset['@id']).includes(entry.dataset['@id'])) {
            ownedAssignedDifference.push(entry);
          }
        }
        if (ownedAssignedDifference.length === 0) {
          this.allDatasetsAssigned = true;
        }
        else if (datasets.length === 0) {
          this.allDatasetsNonAssigned = true;
        }
        this.setState({
          assignedDatasets: processDatasets(datasets).values(),
          ownedDatasets: processDatasets(this.props.datasets).values(),
          nonAssignedDatasets: processDatasets(ownedAssignedDifference).values()
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          assignedDatasets: null,
          ownedDatasets: processDatasets(this.props.datasets).values()
        });
      });
  }

   getDatasetsProgress(datasets) {
    let uuids = [];
    let names = [];
    let currentTimestamp = this.state.selectionTimestamp;

    for (let dataset of datasets) {
      uuids.push(dataset.dataset['@id'].split('dataset/')[1]);
      names.push(filterByLanguage(dataset.dataset, 'http://www.w3.org/2000/01/rdf-schema#label', 'el'));
    }

    for (let index = 0; index < uuids.length; index++) {
      getDatasetProgress(uuids[index])
        .then(response => {
          if (this.state.selectionTimestamp != currentTimestamp) {
            return;
          }
          let p = response.sort((a, b) => (a.progress > b.progress) ? 1 : -1);
          let entry = {
            uuid: uuids[index],
            name: names[index],
            progArray: p,
            avgProgress: this.getAverageProgress(response)
          };
          let prog = this.state.datasetsProgress;
          prog.push(entry);
          prog.sort((a, b) => (a.avgProgress > b.avgProgress) ? 1 : ((b.avgProgress > a.avgProgress) ? -1 : 0));
          this.setState({ datasetsProgress: prog });
        })
        .catch(error => console.error(error));
    }
  }

  getAverageProgress(progArray) {
    if (progArray.length > 0) {
      let progs = progArray.map(el => el.progress);
      return parseFloat((progs.reduce((a, b) => a + b, 0) / progs.length).toFixed(2));
    }
    return 101;
  }

  formatProperty(name) {
    let n = name.split('/').pop();
    return n.charAt(0).toUpperCase() + n.slice(1);
  }

  joinGroup(editorId) {
    joinEditor(editorId)
      .then(response => {
        this.throwToast('success', response.message);
        this.getEditors();
      })
      .catch(error => {
        console.error(error);
        this.throwToast('error', 'You have already joined this Editor.');
      })
  }

  selectUser(user) {
    if (this.state.selectedUser == user) {
      return;
    }
    else {
      this.state.everythingLoaded = false;
      this.allDatasetsAssigned = false;
      this.allDatasetsNonAssigned = false;
      this.setState({ selectedUser: user, selectionTimestamp: Date.now(), datasetsProgress: [] });
      this.getAssignedDatasets(user.id);
    }
  }

  removeValidator(validatorId) {
    if (window.confirm('Are you sure you want to remove this validator from your list?')) {
      removeValidator(validatorId)
        .then(response => {
          this.setState({ selectedUser: null, selectionTimestamp: -1 });
          this.getValidators();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  removeDatasetAccess(datasetUri) {
    if (window.confirm('Are you sure you want to remove this dataset access from the validator?')) {
      removeDatasetAccess(datasetUri, this.state.selectedUser.id)
        .then(response => {
          this.allDatasetsAssigned = false;
          this.throwToast("success", "Dataset access removed.");
          this.getAssignedDatasets(this.state.selectedUser.id);
        })
        .catch(error => {
          console.error(error);
          this.throwToast("error", "Removing dataset access failed.");
        });
    }
  }

  addDatasetAccess(datasetId) {
    createDatasetAccess(this.state.selectedUser.id, null, datasetId.split('/dataset/')[1])
      .then(response => {
        this.allDatasetsNonAssigned = false;
        this.getAssignedDatasets(this.state.selectedUser.id);
        this.throwToast('success', 'You have granted dataset access to the Validator.');
      })
      .catch(error => {
        this.throwToast('error', 'The Validator has already access to this dataset.');
        console.error(error);
      });
  }

  giveAccessToAll(id) {
    giveAccessToAllDatasets(id)
      .then(response => {
        this.allDatasetsNonAssigned = false;
        this.getAssignedDatasets(id);
        this.throwToast('success', `You have granted access to the remaining ${response.length} datasets to the Validator.`);
      })
      .catch(error => {
        this.throwToast('error', 'Giving access to all datasets, failed');
        console.error(error);
      })
  }

  removeAccessFromAllDatasets(id) {
    if (window.confirm('Are you sure you want to remove access to all datasets for this validator?')) {
      removeAccessFromAll(id)
        .then(response => {
          this.allDatasetsAssigned = false;
          this.getAssignedDatasets(id);
          this.throwToast('success', 'You have removed access to all datasets for the validator.');
        })
        .catch(error => {
          this.throwToast('error', 'Removing access from all datasets, failed');
          console.error(error);
        })
    }
  }

  throwToast(type, message) {
    if (type === 'error') {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (type === 'success') {
      toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  componentDidMount() {
    if (this.props.mode === 'validator') {
      this.getPublicEditors();
      this.getEditors();
    }
    else if (this.props.mode === 'editor') {
      this.getValidators();
      this.setState({ ownedDatasets: processDatasets(this.props.datasets).values() });
    }
  }

  componentWillReceiveProps() {
    if (this.state.selectedUser) {
      this.getAssignedDatasets(this.state.selectedUser.id);
    }
  }

  jsonldsort(a, b) {
    var t1 = filterByLanguage(a.dataset, 'http://www.w3.org/2000/01/rdf-schema#label', 'en');
    var t2 = filterByLanguage(b.dataset, 'http://www.w3.org/2000/01/rdf-schema#label', 'en');

    if (t1 < t2) {
      return -1;
    } else if (t1 > t2) {
      return 1;
    } else {
      return 0;
    }
  }

  render() {
    return (
      <Container>
        <Row>
          {/* Left-side menu */}
          <Col md={3}>
            <Container className="groupborder pb-0">
              <Row className="header mb-0">
                <Col>
                  {this.props.mode === 'validator' ? Localizer.dashboard.editors[this.props.language]
                    : Localizer.dashboard.validators[this.props.language]}
                </Col>
              </Row>
              <Row className="content">
                {this.props.mode === 'validator' &&
                  <ListGroup>
                    {Array.from(this.state.myEditors).map(editor => (
                      <ListGroup.Item
                        action
                        key={editor.id}
                        variant={(this.state.selectedUser && this.state.selectedUser.id === editor.id) ? "secondary" : ""}
                        onClick={() => this.selectUser(editor)}
                      >
                        {editor.jobDescription}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                }
                {this.props.mode === 'editor' &&
                  <ListGroup>
                    {Array.from(this.state.myValidators).map(validator => (
                      <ListGroup.Item
                        action
                        key={validator.id}
                        variant={(this.state.selectedUser && this.state.selectedUser.id === validator.id) ? "secondary" : ""}
                        onClick={() => this.selectUser(validator)}
                      >
                        {validator.name}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                }
              </Row>
            </Container>
            {this.props.mode === 'validator' &&
              <Row>
                <Col className="dashboardSelectorEditors">
                  <DropdownButton title="Join Editor ">
                    {Array.from(this.state.publicEditors).map((editor, index) => (
                      <Dropdown.Item as="button" key={"public-editor-" + index} onClick={() => this.joinGroup(editor.id)}>
                        {editor.jobDescription}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Col>
              </Row>
            }
          </Col>
          {/* Validator Content */}
          {this.props.mode === 'validator' &&
            <Col>
              {!this.state.selectedUser &&
                <Row className="mt-3 text-center">
                  <Col>Select an editor from the list on the left, to view the shared datasets.</Col>
                </Row>
              }
              {this.state.selectedUser &&
                <div>
                  <Row className="header mt-2 text-center border-top">
                    <Col>
                      Datasets assigned by {this.state.selectedUser.jobDescription}
                    </Col>
                  </Row>
                  {this.state.assignedDatasets &&
                    <Row className="align-items-center">
                      <Col className="pt-3">
                        {Array.from(this.state.datasetsProgress).map((dataset, index) => {
                          this.state.everythingLoaded = (this.state.assignedDatasetslength <= index + 1);
                          return (
                            <Row className="mb-3" key={"table-" + index}>
                              <Table borderless striped size="md">
                                <thead>
                                  <tr key={"dataset-" + index} className="border-bottom border-secondary">
                                    <th className="w-50">
                                      {dataset.name}
                                    </th>
                                    <th className="w-50 pt-3 align-items-center">
                                      {dataset.avgProgress <= 100 &&
                                        <Row>
                                          <Col className="align-self-center w-85" md="auto">
                                            <ProgressBar animated now={dataset.avgProgress} />
                                          </Col>
                                          <Col className="align-self-center w-15 pl-0" md="auto">
                                            {dataset.avgProgress}%
                                          </Col>
                                        </Row>
                                      }
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dataset.progArray.length === 0 &&
                                    <tr key={"dataset-" + index + "-property-0"}>
                                      <td colSpan="2" className="text-center font-italic">
                                        No validations running for this dataset
                                      </td>
                                    </tr>
                                  }
                                  {dataset.progArray.length > 0 &&
                                    Array.from(dataset.progArray).map((entry, i) => (
                                      <tr key={"dataset-" + index + "-property-" + i}>
                                        <td className="w-50 align-middle">
                                          <Row>
                                            <Col className="w-80 pr-2" md="auto">
                                              {entry.propertyName}
                                            </Col>
                                            <Col className="w-20 pr-2" md="auto">
                                              ({this.formatProperty(entry.asProperty)})
                                            </Col>
                                          </Row>
                                        </td>
                                        <td className="w-50 pt-3 align-items-center align-middle">
                                          <Row>
                                            <Col className="align-self-center w-85 align-middle" md="auto">
                                              <ProgressBar>
                                                <ProgressBar animated variant="success" now={entry.totalAnnotations === 0 ? 0 : (entry.totalAccepted / entry.totalAnnotations * 100).toFixed(2)} />
                                                <ProgressBar animated variant="secondary" now={entry.totalAnnotations === 0 ? 0 : (entry.totalNeutral / entry.totalAnnotations * 100).toFixed(2)} />
                                                <ProgressBar animated variant="danger" now={entry.totalAnnotations === 0 ? 0 : (entry.totalRejected / entry.totalAnnotations * 100).toFixed(2)} />
                                              </ProgressBar>
                                            </Col>
                                            <Col className="align-self-center w-15 pl-0" md="auto">
                                              {entry.progress}%
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </Table>
                            </Row>
                          )
                        })}
                      </Col>
                    </Row>
                  }
                  {!this.state.everythingLoaded && this.state.assignedDatasetslength > 0 &&
                    <LoadingComponent />
                  }
                  {this.state.assignedDatasetslength == 0 &&
                    <Row>
                      <Col className="text-center mt-3">
                        {this.state.selectedUser.jobDescription} hasn't assigned any datasets to you yet.
                      </Col>
                    </Row>
                  }
                </div>
              }
            </Col>
          }
          {/* Editor Content */}
          {this.props.mode === 'editor' &&
            <Col>
              {!this.state.selectedUser &&
                <Row className="mt-3 text-center">
                  <Col>Select a validator from the list on the left, to give access to datasets.</Col>
                </Row>
              }
              {this.state.selectedUser &&
                <div>
                  <Row className="header mt-2 text-center border-top">
                    <Col>Validator</Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col>
                      <Table borderless size="sm" className="mb-0">
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <td>{this.state.selectedUser.name}</td>
                          </tr>
                          <tr>
                            <th>E-mail</th>
                            <td>{this.state.selectedUser.email}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col>
                      <Button variant="outline-danger" onClick={() => this.removeValidator(this.state.selectedUser.id)}>Remove validator</Button>
                    </Col>
                  </Row>
                  <Row className="mt-4 font-weight-bold">
                    {this.state.nonAssignedDatasets && !this.allDatasetsAssigned &&
                      <React.Fragment>
                        <Col className="mt-3" xs={7}>Select a new dataset, to give access to:</Col>
                        <Col className="dashboardSelectorDatasets" xs={!this.allDatasetsNonAssigned ? 2 : null}>
                          <DropdownButton title="Assign new dataset " variant="outline-primary" >
                            <Dropdown.Item as="button" className="bold" onClick={() => this.giveAccessToAll(this.state.selectedUser.id)}>Give access to all</Dropdown.Item>
                            <Dropdown.Divider />
                            <div className="dropdown-scroll">
                              {Array.from(this.state.nonAssignedDatasets).sort(this.jsonldsort).map((el, index) => (
                                <Dropdown.Item as="button" key={"editor-dataset-" + index} onClick={() => this.addDatasetAccess(el.dataset['@id'])}>
                                  {filterByLanguage(el.dataset, 'http://www.w3.org/2000/01/rdf-schema#label', 'el')}
                                </Dropdown.Item>
                              ))}
                            </div>
                          </DropdownButton>
                        </Col>
                        {!this.allDatasetsNonAssigned > 0 ?
                          <Col className="dashboardSelectorDatasets" xs={3}><Button className="btn btn-danger" onClick={() => this.removeAccessFromAllDatasets(this.state.selectedUser.id)}>Remove all Datasets</Button></Col>
                          : null}
                      </React.Fragment>
                    }
                    {this.state.nonAssignedDatasets && this.allDatasetsAssigned &&
                      <React.Fragment>
                        <Col className="mt-3" xs={9}>This user has access to all datasets</Col>
                        <Col className="dashboardSelectorDatasets" xs={3} ><Button className="btn btn-danger" onClick={() => this.removeAccessFromAllDatasets(this.state.selectedUser.id)}>Remove all Datasets</Button></Col>
                      </React.Fragment>
                    }
                  </Row>

                  {this.state.assignedDatasets &&
                    <div>
                      <Row className="header mt-4 text-center">
                        <Col>Assigned Datasets</Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col>
                          <TableScrollbar rows={12}>
                            <Table borderless striped size="sm" className="mt-3 mb-0">
                              <tbody>
                                {Array.from(this.state.assignedDatasets).map((el, index) => (
                                  <tr key={"assigned-dataset-" + index}>
                                    <td>
                                      {filterByLanguage(el.dataset, 'http://www.w3.org/2000/01/rdf-schema#label', 'el')}
                                    </td>
                                    <td>
                                      <Button type="button" className="deleteeditbutton mt-0" aria-label="Delete" onClick={() => this.removeDatasetAccess(el.dataset['@id'])}>
                                        <span className="fa fa-times failed" title="Remove dataset access" />
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </TableScrollbar>
                        </Col>
                      </Row>
                    </div>
                  }
                </div>
              }
            </Col>
          }
        </Row>

        {/* <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </Container>
    );
  }
}

export default Dashboard;
